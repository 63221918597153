<template>
  <div class="main-screen">
    <!-- Left Navbar -->
    <div  :class="['navbar-section', { hidden: !isNavbarVisible }]">
      <Navbar ref="navbar"
        @toggle-navbar="adjustMainContent"
      />
    </div>

    <!-- Center Main Content -->
    <div :class="['main-content', { 'full-width': !isNavbarVisible }]" style="margin-left:20px">
      <div class="header">
        <h1> <a href="/projectPage" class="text-decoration-underline text-dark"> Your Projects   </a> &gt; {{this.projectTitle}}  </h1>
      </div>
       <div v-if="isCreateModal" class="modal-overlay">
          <div class="modal">
            <h3>Create/Edit Task</h3>
            <div class="form">
              <div class="form-group">
                <label for="title">Task Title</label>
                <input type="text" v-model="taskTitle" id="taskTitle" placeholder="Enter Task Title" required />
              </div>
              
            <div class="form-group">
              <label for="status">Task Status</label>
              <select v-model="taskStatus" class="form-select" id="status" required>
                <option value="New">New</option>
                <option value="In Progress">In Progress</option>
                <option value="Completed">Completed</option>
              </select>
            </div>

              <div class="form-group">
                <label for="taskStartDate">Task Start Date</label>
                <input type="date" v-model="taskStartDate" id="taskStartDate"   placeholder="Enter Start Date" required/>
              </div>
              <div class="form-group">
                <label for="address">Task End Date</label>
                <input type="date" v-model="taskEndDate" id="taskEndDate" placeholder="Enter End Date" required/>
              </div>
              <div class="form-group">
                <label for="projectDesc">Task Description</label>
                <input type="text" v-model="taskDesc" id="taskDesc" placeholder="Enter Task Description" required/>
              </div>
            </div>
            <div class="modal-footer">
              <button @click="closeModal" class="cancel-button">Cancel</button>
              <button :disabled="isSaving" @click="createOrEditData" class="save-button-modal">
                <span v-if="!isSaving">Save</span>
                <span v-else>Saving...</span>
              </button>
            
            </div>
            <p v-if="errorMessage" style="color:#E53B46" class="error-message">{{ errorMessage }}</p>
          </div>
        </div>
        <div v-if="deleteModal" class="modal-overlay">
          <div class="modal">
            <h3>Delete Task</h3>
              <h6>Are you sure you want to delete?</h6>
              <div class="modal-footer">
                <button @click="closeDeleteModal" class="cancel-button">Cancel</button>
                <button :disabled="isDeleting" @click="deleteTask" class="delete-button-modal">
                  <span v-if="!isDeleting">Delete</span>
                  <span v-else>Deleting...</span>
                </button>
              </div>
              <p v-if="errorMessage" style="color:red" class="error-message">{{ errorMessage }}</p>
          </div>
        </div>
       <div class="todo-lists-container">
          <!-- To-Do List Section -->
          <div class="card todo-list scrollable">
            <h2 style="margin-bottom:20px">
              To-Do List
              <button class="add-button" @click="addTask">+</button>
            </h2>
            <div v-for="(task, index) in todoList" :key="index" class="todo-card">
              <div class="card-header">
                <h5>{{ task.title }}</h5>
                 <div class="action-icons">
                  <a href="#" @click.prevent="editTask(task)">
                    <i class="fa-regular fa-pen-to-square"></i>
                  </a>
                  <a href="#" @click.prevent="openDeleteModal(task)">
                    <i class="fa-solid fa-trash"></i>
                  </a>
                 </div>
              </div>
              <div class="card-body">
                <h6>Status: <span style="font-weight: normal;">{{ task.status }}</span> </h6>
                <h6>Start Date: <span style="font-weight: normal;"> {{ new Date(task.startDate).toDateString() }}</span></h6>
                <h6>End Date: <span style="font-weight: normal;">{{ new Date(task.endDate).toDateString() }}</span></h6>
                <h6>Description: <span style="font-weight: normal;">{{ task.desc }}</span></h6>
              </div>

            </div>
          </div>
            <div class="card todo-list" style="padding: 20px; border: 1px solid #ddd; border-radius: 10px; max-width: 350px; font-family: Arial, sans-serif;">
                <h2 style="margin-bottom: 20px; font-size: 18px; font-weight: bold;">
                  Lead's Information
                  <button v-if="leadEdit  && !leadLoading" class="save-button" @click="toggleEdit(false)">
                    <i class="fa-solid fa-pen-to-square"></i> Edit
                  </button>
                  <button v-if="!leadEdit" :disabled="leadLoading" class="save-button" @click="toggleEdit(true)">
                    <template v-if="!leadLoading">
                      <i class="fa-regular fa-floppy-disk"></i> Save
                    </template>
                    <div v-else class="spinner"></div>

                  </button>
                </h2>
                <div style="margin-bottom: 10px;">
                  <div style="display: flex; justify-content: space-between; width: 100%;">
                    <span style="font-weight: bold;">Name:</span>
                    <template v-if="!leadEdit">
                      <input v-model="name" style="width: 60%;" class="underline-input" placeholder="Enter Lead's Name"/>
                    </template>
                    <template v-else>
                      <span>{{ name }}</span>
                    </template>
                  </div>
                </div>
                <div style="margin-bottom: 10px;">
                  <div style="display: flex; justify-content: space-between; width: 100%;">
                    <span style="font-weight: bold;">Email:</span>
                    <template v-if="!leadEdit">
                      <input v-model="email" style="width: 60%;" class="underline-input" placeholder="Enter Lead's Email" />
                    </template>
                    <template v-else>
                      <span>{{ email }}</span>
                    </template>
                  </div>
                </div>
                <div style="margin-bottom: 10px;">
                  <div style="display: flex; justify-content: space-between; width: 100%;">
                    <span style="font-weight: bold;">Phone:</span>
                    <template v-if="!leadEdit">
                      <input v-model="phone" style="width: 60%;" class="underline-input" placeholder="Enter Lead's Phone No." />
                    </template>
                    <template v-else>
                      <span>{{ phone }}</span>
                    </template>
                  </div>
                </div>
                <!-- <div style="margin-bottom: 10px;">
                  <div style="display: flex; justify-content: space-between; width: 100%;">
                    <span style="font-weight: bold;">Property Type:</span>
                    <template v-if="!leadEdit">
                      <input v-model="pType" style="width: 60%;"  class="underline-input" placeholder="Enter Lead's Property type"/>
                    </template>
                    <template v-else>
                      <span>{{ pType }}</span>
                    </template>
                  </div>
                </div> -->
                <div style="margin-bottom: 10px;">
                  <div style="display: flex; justify-content: space-between; width: 100%;">
                    <span style="font-weight: bold;">Budget:</span>
                    <template v-if="!leadEdit">
                      <input v-model="budget" style="width: 60%;" class="underline-input" placeholder="Enter Lead's Budget"/>
                    </template>
                    <template v-else>
                      <span>{{ budget }}</span>
                    </template>
                  </div>
                </div>
                <!-- <div style="margin-bottom: 10px;">
                  <div style="display: flex; justify-content: space-between; width: 100%;">
                    <span style="font-weight: bold;">Style:</span>
                    <template v-if="!leadEdit">
                      <input v-model="style" style="width: 60%;" class="underline-input" placeholder="Enter the Style of the House" />
                    </template>
                    <template v-else>
                      <span>{{ style }}</span>
                    </template>
                  </div>
                </div> -->
                <div style="margin-bottom: 10px;">
                  <div style="display: flex; justify-content: space-between; width: 100%;">
                    <span style="font-weight: bold;">Additional Info:</span>
                    <template v-if="!leadEdit">
                      <input v-model="addInfo" style="width: 60%;" class="underline-input" placeholder="Enter Any Additional info" />
                    </template>
                    <template v-else>
                      <span>{{ addInfo }}</span>
                    </template>
                  </div>
                </div>
                <div style="margin-bottom: 10px;">
                  <div style="display: flex; justify-content: space-between; width: 100%;">
                    <span style="font-weight: bold;">Address:</span>
                    <template v-if="!leadEdit">
                      <input v-model="address" style="width: 60%;"  class="underline-input" placeholder="Enter Lead's Address"/>
                    </template>
                    <template v-else>
                      <span>{{ address }}</span>
                    </template>
                  </div>
                </div>
              </div>


        </div>
    </div>
    <!-- Right Preview -->
    <!-- <div class="preview-section" v-if="!isMobile"  style="margin-right:20px;margin-top:20px">
      <div class="preview-card">
       <h3 class="preview-title">Progress Chart</h3>
          <div v-for="(data, key) in progressData" :key="key" class="progress-item">
            <div class="progress-label">
              <span>{{ data.title }}</span>
              <span>{{ data.progress.toFixed(2) }}%</span>
            </div>
            <div class="progress-bar">
              <div
                class="progress-fill"
                :style="{ width: data.progress + '%' }"
              ></div>
            </div>
          </div>
      </div>
    </div> -->
  
  </div>
</template>
<script>
import axios from "axios";
import Navbar from "../components/navbar.vue";
import router from "@/router";
import {
  getAuth,
  onAuthStateChanged
} from "firebase/auth";
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      isNavbarVisible :true,
      defaultPlaceholder: "https://via.placeholder.com/150", // Placeholder image
      accID: "",
      response: "",
      id_token: "",
      apiURL: "https://backend-updateuser-project-553185792079.us-central1.run.app",
      projectCounter: 0, 
      projectList: [],
      isMobile: false, // Detect mobile view
      isModalOpen: false,
      isSaving: false, // State to show spinner and disable button
      errorMessage: "", // State to hold error message
      projectInfos:{},
      projectid:"",
      projectTitle:"",
      todoList:[],
      name:"",
      email:"",
      phone:"",
      pType:"",
      budget:"",
      style:"",
      address:"",
      addInfo:"",
      leadEdit:true,
      leadLoading:false,
      taskTitle:"",
      taskStatus:"",
      taskStartDate:"",
      taskEndDate:"",
      taskDesc:"",
      isCreateModal:false,
      taskID:"",
      createOrEdit:true,
      deleteModal:false,
      deleteTaskInfo:{},
      isDeleting: false,
      industry: ""
    };
  },
  methods: {
    openModal() {
      this.isCreateModal = true;
    },
    closeModal() {
      this.isCreateModal = false;
      // this.projectTitle=""
      // this.projectID=""
      // this.projectAddress=""
      // this.houseSize =""
      // this.startDate=""
      // this.selectedTasks=[]
    },
    openDeleteModal(task){
      this.deleteModal = true;
      this.deleteTaskInfo = task;
    },
    closeDeleteModal(){
      this.deleteTaskInfo={}
      this.deleteModal = false;
    },
    async checkAuthState() {
      const auth = getAuth();
      return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            try {
              const idToken = await user.getIdToken();
              this.id_token = idToken;
              resolve(); // Resolve after id_token is set
            } catch (error) {
              console.error("Error fetching ID token:", error);
              reject(error); // Reject in case of errors
            }
          } else {
            router.push("/");
            reject("No user authenticated");
          }
        });
      });
  },
      checkScreenSize() {
      this.isMobile = window.innerWidth <= 768; // Set breakpoint for mobile
      if (this.isMobile) {
        this.isPreviewVisible = false; // Hide preview on mobile
        this.isNavbarVisible = false;
        this.$refs.navbar.toggleNavbar(false);
      }
    },
    adjustMainContent(isVisible) {
      this.isNavbarVisible = isVisible; 
    },

    toggleEdit(savingStatus) {
        this.leadLoading = true;

        if (savingStatus) {
          axios
            .put(this.apiURL + "/editLead", {
              accID: this.currUserId,
              id_token: this.id_token,
              projectID: this.projectid,
              name:this.name, 
              email: this.email,
              phone: this.phone, 
              pType: this.pType, 
              budget:this.budget, 
              style:this.style, 
              addInfo: this.addInfo, 
              address: this.address
            })
            .then((response) => {
              if (response.status === 401) {
                alert("Session expired! Please log in again.");
                router.push("/");
                return;
              }
            })
            .catch((error) => {
              console.error("Error updating description:", error);
              })
            .finally(() => {
              this.leadEdit = !this.leadEdit; // Toggle edit mode
              this.leadLoading = false; // Stop spinner
            });
        } else {
          // If not saving, just toggle edit mode
          this.leadEdit = !this.leadEdit;
          this.leadLoading = false;
        }
      },
      addTask(){
        this.openModal();
        this.createOrEdit=true
      },
      async deleteTask(){
        var task = this.deleteTaskInfo
        this.isDeleting = true
        await axios
        .post(this.apiURL + "/deleteTodo", {
          userID: this.accID,
          taskID:task.todoID,
          id_token: this.id_token,
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return;
          }
          this.refreshTaskList();
          this.isDeleting = false
          this.closeDeleteModal();

        })
        .catch(() => {
        });
        return "OK"
      },
      editTask(task){
          this.openModal();
          this.taskTitle = task.title
          this.taskStartDate = this.formatDate(new Date(task.startDate))
          this.taskEndDate = this.formatDate(new Date(task.endDate))
          this.taskDesc = task.desc
          this.taskStatus = task.status
          this.taskID= task.todoID
          this.createOrEdit=false
      },
      createOrEditData(){
        if(this.createOrEdit){
          this.saveTask()
        }else{
          this.saveEditedTask()
        }
      },
      async saveEditedTask(){
      await axios
        .put(this.apiURL + "/editTodo", {
          userID: this.accID,
          title: this.taskTitle,
          startDate:this.taskStartDate,
          endDate:this.taskEndDate,
          desc:this.taskDesc,
          status:this.taskStatus,
          todoID:this.taskID,
          id_token: this.id_token,
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return;
          }
          if (response.status==428){
             this.errorMessage = response.message
          }
         this.taskTitle = ""
         this.taskStartDate=""
         this.taskEndDate=""
         this.taskDesc = ""
         this.taskStatus = ""
        this.closeModal()
         this.refreshTaskList();
        })
        .catch((error) => {
          if (error.response && error.response.status === 428) {
            this.errorMessage = "Project ID Have been used please try something else";
          } else {
            console.error("Error saving project:", error);
            this.errorMessage = "Failed to save project. Please try again.";
          }
        })
        .finally(()=>{
        this.isSaving = false; // Reset spinner and enable button
      });
      return "OK"
    },
      async saveTask(){
        if (this.taskStatus== "" || this.taskStatus==undefined){
          this.taskStatus = "New";
        }
      await axios
        .post(this.apiURL + "/createTodo", {
          userID: this.accID,
          title: this.taskTitle,
          projectID: this.projectid,
          startDate:this.taskStartDate,
          endDate:this.taskEndDate,
          desc:this.taskDesc,
          status:this.taskStatus,
          id_token: this.id_token,
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return;
          }
          if (response.status==428){
             this.errorMessage = response.message
          }
         this.taskTitle = ""
         this.taskStartDate=""
         this.taskEndDate=""
         this.taskDesc = ""
         this.taskStatus = ""
        this.closeModal()
        this.refreshTaskList(); 
        })
        .catch((error) => {
          if (error.response && error.response.status === 428) {
            this.errorMessage = "Project ID Have been used please try something else";
          } else {
            console.error("Error saving project:", error);
            this.errorMessage = "Failed to save project. Please try again.";
          }
        })
        .finally(()=>{
        this.isSaving = false; // Reset spinner and enable button
      });
      return "OK"
    },
     formatDate(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    async refreshTaskList(){
      axios.get(this.apiURL + "/getTodo/"+this.projectid, {
            headers: {
              Authorization: this.id_token
            },
          })
          .then((response) => {
            if (response.status == 401) {
              alert("Login Expire! Please Login Again");
              router.push("/");
              return ;
            } 
            const projectInfos = response.data.message; // Example response: { '123': { total: 11, done: 0 }, '456': { total: 8, done: 5 } }
            // this.progressData = todos;
            this.projectInfos  = projectInfos
            this.projectTitle = projectInfos.projectInfo.title 
            this.todoList = projectInfos.todoList
            this.name = projectInfos.projectInfo.name
            this.email =  projectInfos.projectInfo.email
            this.phone = projectInfos.projectInfo.phone
            this.pType = projectInfos.projectInfo.pType
            this.budget = projectInfos.projectInfo.budget
            this.style = projectInfos.projectInfo.style
            this.address = projectInfos.projectInfo.address
            this.addInfo = projectInfos.projectInfo.addInfo
          })
          .catch(() => {
            // Handle error
            // return "error"
          });
            // return "OK"
    
    }

  },
  async mounted() {

    this.accID = this.$store.getters.getUserId;
    await this.checkAuthState();
    this.checkScreenSize(); // Check on page load
    this.projectid = this.$route.query.projectid;
    if (this.projectid == undefined || this.projectid == "") {
      router.push("/NotFound");
    }
    window.addEventListener("resize", this.checkScreenSize); // Watch for window resize
    window.addEventListener("touchmove", (event) => {
      if (this.isMobile) {
        event.stopPropagation();
      }
    });
    axios.get(this.apiURL + "/getTodo/"+this.projectid, {
            headers: {
              Authorization: this.id_token
            },
          })
          .then((response) => {
            if (response.status == 401) {
              alert("Login Expire! Please Login Again");
              router.push("/");
              return ;
            } 
            const projectInfos = response.data.message; // Example response: { '123': { total: 11, done: 0 }, '456': { total: 8, done: 5 } }
            // this.progressData = todos;
            this.projectInfos  = projectInfos
            this.projectTitle = projectInfos.projectInfo.title 
            this.todoList = projectInfos.todoList
            this.name = projectInfos.projectInfo.name
            this.email =  projectInfos.projectInfo.email
            this.phone = projectInfos.projectInfo.phone
            this.pType = projectInfos.projectInfo.pType
            this.budget = projectInfos.projectInfo.budget
            this.style = projectInfos.projectInfo.style
            this.address = projectInfos.projectInfo.address
            this.addInfo = projectInfos.projectInfo.addInfo
            this.industry = projectInfos.projectInfo.industry
          })
          .catch(() => {
            // Handle error
            // return "error"
          });
            // return "OK"
    
            
  }
};
</script>
<style scoped>
.main-screen {
  display: flex;
  /* height: 100vh; */
  gap: 20px;
}

.navbar-section {
  width: 250px;
  text-align: left;
}

.main-content {
  height: 100vh;
  flex: 2;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow-y: auto;
  text-align: left !important; /* Override Bootstrap */
  justify-content: flex-start !important; /* Ensure left alignment */
  align-items: flex-start !important; /* For flex containers */
}

.profile-picture img {
  max-width: 100px;
  border-radius: 50%;
  /* display: block; */
  /* margin: 0 auto 10px; */
}

/* .profile-picture input {
  display: block;
  margin: 10px auto;
} */

.sections .section {
  margin-bottom: 20px;
}

.preview-section {
  width: 350px; /* Matches the card width */
  height: auto; /* Adjusts to the content height */
  z-index: 1000; /* Ensures it stays above other elements */
  background-color: transparent; /* Transparent background to blend with the page */
}
.navbar-section {
  transition: width 0.3s ease;
  overflow: hidden; /* Prevent content overflow when hidden */
}

.navbar-section.hidden {
  width: 0; /* Collapse navbar */
}
.main-content {
  flex: 2;
  transition: flex 0.3s ease; /* Smooth adjustment */
}

.main-content.full-width {
  flex: 1; /* Take over the space */
}
.main-screen{
  background-color: #f9f9f9;
  text-align: left !important; /* Override Bootstrap */
  justify-content: flex-start !important; /* Ensure left alignment */
  align-items: flex-start !important; /* For flex containers */
}

/* Spinner Style */
.spinner {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  }
 
.add-project {
  /* padding: 10px 15px; */
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  margin-bottom: -7px;
  margin-left: 5px
}

.add-project:hover {
  background-color: #f0f0f0;
}
.header {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 24px;
  margin: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.modal {
  background: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 700px;
  width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
  display: initial;
  position: relative;
  height: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.save-button {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-size: 0.9rem;
  border: 1px solid #757575;
  background-color: #fff;
  color: #333;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.save-button:hover {
  background-color: #f5f5f5;
}

.save-button i {
  margin-right: 5px;
}

.save-button-modal {
  background-color: #63D58F;
  color: white;
}

.delete-button-modal {
  background-color: #E53B46;
  color: white;
}

::-webkit-scrollbar {
            width: 12px;
        }

::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
    box-shadow: inset 0 0 6px rgba(100, 100, 100, 0.3);
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #8b8b8b;
}
.card {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.card h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 15px;
}

li strong {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
}

a {
  color: inherit;
  text-decoration: none;
  margin-left: 5px;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

p {
  margin: 0;
  font-size: 0.9rem;
  color: #555;
}

.add-button {
  display: flex; /* Makes the button a flex container */
  justify-content: center; /* Centers the content horizontally */
  align-items: center; /* Centers the content vertically */
  background: #000;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  /* padding: 0;  */
  line-height: 1; /* Ensures the text is properly aligned */
  padding-bottom:5px;
}

.add-button:hover {
  background: #555;
}
.todo-section {
  margin-top: 20px;
}

.todo-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
}
.card-body {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  font-size: 1.1rem;
}

.card-header strong {
  font-weight: bold;
}

.task-description,
.task-time {
  margin: 5px 0;
  color: #555;
  font-size: 0.9rem;
}
.todo-lists-container {
  display: flex; /* Use flexbox for side-by-side layout */
  gap: 20px; /* Add space between cards */
  flex-wrap: wrap; /* Ensure cards wrap on smaller screens */
  
}

.card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1; /* Make all cards take equal space */
  min-width: 300px; /* Ensure cards do not shrink too much */
  height: 80vh;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}

.add-button:hover {
  background: #555;
}
/* Scrollable card */
.todo-list.scrollable {
  /* height: 400px;  */
  overflow-y: auto; /* Enables vertical scrolling */
}
  .underline-input {
    border: none;
    border-bottom: 1px solid #ddd;
    outline: none;
    width: 60%; /* Adjust width as needed */
    font-size: 14px;
    padding: 5px 0;
    background: transparent; /* Optional: transparent background for cleaner look */
  }

  .underline-input:focus {
    border-bottom: 1px solid #000; /* Optional: highlight underline on focus */
  }

  
.cancel-button,
.save-button-modal,
.delete-button-modal {
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button {
  background-color: #f0f0f0;
}
.action-icons {
  display: flex;
  gap: 10px; /* Adjust spacing between icons as needed */
}

.action-icons a {
  text-decoration: none;
  color: inherit;
}

</style>