<template>
  <div class="login-container">
    <div class="gray-box">
      <div class="login-box">
        <div class="logo">
          <img src="../assets/TNT_LOGO_W_NAME.png" alt="TNT Logo" />
        </div>
        <h2>Sign Up</h2>
        <form @submit.prevent="signup">
          <div class="form-group">
            <input
              type="text"
              v-model="signUpProductCode"
              placeholder="Product Code"
              required
            />
          </div>
          <div class="form-group">
            <input
              type="email"
              v-model="signUpEmail"
              placeholder="Email"
              required
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              v-model="signUpPassword"
              placeholder="Password"
              required
            />
          </div>
          <!-- Error Message -->
          <p v-if="loginError" class="error-message">{{ loginError }}</p>
          <button type="submit" class="btn-login">Sign Up</button>
        </form>
        <div class="links">
          <a href="/">Login</a>
          <a href="/forgetPassword">Forgot your password?</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import router from "@/router";
import store from "../store";
import {
  getAuth,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  setPersistence,
} from "firebase/auth";

export default {
  data() {
    return {
      signUpEmail: "",
      signUpPassword: "",
      signUpProductCode: "",
      loginError: "",
      signupSuccess: "",
      apiURL: "https://backend-updateuser-project-553185792079.us-central1.run.app",
    };
  },
  methods: {
    async signup() {
      // Basic validation for password length
      if (this.signUpPassword.length < 6) {
        this.loginError = "Password must be at least 6 characters!";
        return;
      }

      try {
        // Call the backend API for signup
        await axios.post(this.apiURL + "/signup", {
          code: this.signUpProductCode,
          email: this.signUpEmail,
          password: this.signUpPassword,
        });
        this.loginError = ""; // Clear previous errors
        this.signupSuccess = "Signup successful! Logging you in...";
        await this.login(); // Automatically log in after signup
      } catch (error) {
        console.error("Signup failed:", error);
        this.loginError =
          error.response?.data?.message || "An error occurred during signup.";
      }
    },
    login() {
      const auth = getAuth();

      setPersistence(auth, browserSessionPersistence)
        .then(() => {
          return signInWithEmailAndPassword(
            auth,
            this.signUpEmail,
            this.signUpPassword
          )
            .then((userCredential) => {
              const userid = userCredential.user.uid;
              store.commit("addUserId", userid);
              router.push("/editUser");
            })
            .catch((error) => {
              console.error("Login failed:", error);
              this.loginError = "Username/Password Error";
            });
        })
        .catch((error) => {
          console.error("Login persistence error:", error);
          this.loginError = "Error Logging In";
        });
    },
  },
};
</script>

<style scoped>
/* General Container */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
}
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 15px;
  text-align: left;
}

/* Gray Box */
.gray-box {
  width: 600px; /* Larger gray box */
  padding: 40px;
  border-radius: 20px;
  background-color: #d3d3d3; /* Light gray background */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Login Box */
.login-box {
  width: 400px;
  padding: 30px;
  border-radius: 15px;
  /* background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  text-align: center;
  margin: auto; /* Center the login box inside the gray box */
}

/* Logo */
.logo {
  margin-bottom: 20px;
}

.logo img {
  max-width: 50%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Login Heading */
h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

/* Form */
.form-group {
  margin-bottom: 15px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  font-size: 1rem;
}

/* Button */
.btn-login {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-login:hover {
  background-color: #333;
}

/* Links */
.links {
  margin-top: 20px;
}

.links a {
  color: #000;
  text-decoration: none;
  font-size: 0.9rem;
  margin: 0 10px;
}

.links a:hover {
  text-decoration: underline;
}

/* Mobile Responsive Design */
@media screen and (max-width: 480px) {
  .gray-box {
    width: 90%; /* Gray box adjusts for smaller screens */
    padding: 20px;
  }

  .login-box {
    width: 100%; /* Login box remains proportional inside gray box */
    padding: 20px;
  }

  h2 {
    font-size: 1.2rem;
  }

  .form-group input {
    padding: 8px;
    font-size: 0.9rem;
  }

  .btn-login {
    padding: 8px;
    font-size: 0.9rem;
  }

  .links a {
    font-size: 0.8rem;
  }
}
</style>
