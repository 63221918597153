import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import EditUser from "../views/editUser.vue"
import NotFound from "../views/404.vue"
import resetPassword from "../views/resetPassword.vue"
import forgetPassword from "../views/ForgetPassword.vue"
import signUp from "../views/signUp.vue"
import intefaceDesign from "../views/interfaceDesign.vue"
import projectPage from "../views/ProjectPage.vue"
import projectInfo from "../views/ProjectInfo.vue"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: resetPassword
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: forgetPassword
  },
  {
    path: '/EditUser',
    name: 'EditUser',
    component: EditUser
  },
  {
    path: '/intefaceDesign',
    name: 'intefaceDesign',
    component: intefaceDesign
  },
  {
    path: '/projectPage',
    name: 'projectPage',
    component: projectPage
  },
  {
    path: '/signUp',
    name: 'signUp',
    component: signUp
  },
  {
    path: '/projectInfo',
    name: 'projectInfo',
    component: projectInfo
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: NotFound 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
