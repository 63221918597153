<template>
  <div class="main-screen">
    <!-- Left Navbar -->
    <div  :class="['navbar-section', { hidden: !isNavbarVisible }]">
      <Navbar ref="navbar"
        @toggle-navbar="adjustMainContent"
      />
    </div>

    <!-- Center Main Content -->
    <div :class="['main-content', { 'full-width': !isNavbarVisible }]" style="margin-left:20px">
      <div class="header">
        <h1>Your Projects ({{projectCounter}})</h1>
        <button @click="openModal" class="add-project"><i class="fa-regular fa-square-plus"></i> Add Project</button>
      </div>
        <!-- Modal -->
        <div v-if="isModalOpen" class="modal-overlay">
          <div class="modal">
            <h3>Add New Project</h3>
            <div class="form">
              <div class="form-group">
                <label for="title">Project Title</label>
                <input type="text" v-model="projectTitle" id="title" placeholder="Enter project title" required />
              </div>
              <div class="form-group">
                <label for="title">Project ID</label>
                <input type="text" v-model="projectID" id="title" placeholder="Enter a Unqiue Code" required />
              </div>
              <div class="form-group">
                <label for="projectDesc">Description</label>
                <input type="text" v-model="projectDesc" id="projectDesc" placeholder="Enter Project Description" required/>
              </div>
              <div class="form-group">
                <label for="industry">Project Type</label>
                <select v-model="industry" class="form-select" id="status" required @change="onIndustryChange">
                  <option value="interiorDesign">Interior Designing</option>
                  <option value="weddingPlanner">Wedding Planning</option>
                </select>
              </div>
              <div v-if="industry=='interiorDesign'" class="form-group">
                <label for="address">House Size</label>
                <input type="text" v-model="houseSize" id="houseSize" placeholder="Enter House Size: 500 Square Feet" required/>
              </div>
              <div v-if="industry=='interiorDesign'" class="form-group">
                <label for="startDate">Start Date</label>
                <input type="date" v-model="startDate" id="startDate" required/>
              </div>
              <div v-if="industry=='weddingPlanner'" class="form-group">
                <label for="endDate">Wedding Date</label>
                <input type="date" v-model="endDate" id="startDate" required/>
              </div>
              <div v-if="industry" class="form-group">
                <label for="tasks">Tasks</label>
                <div id="tasks" style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px; margin-top: 10px;">
                  <div v-for="(task, index) in tasks" :key="index" style="text-align: left;">
                    <button 
                      @click="toggleTask(index)" 
                      :class="{'active': selectedTasks.includes(task)}" 
                      class="task-toggle-button"
                    >
                      {{ task }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="industry" class="form-group">
                <label for="startDate">Other Task</label>
                <input type="text" v-model="otherTask" id="otherTask"/>
              </div>
            </div>
            <div class="modal-footer">
              <button @click="closeModal" class="cancel-button">Cancel</button>
              <button :disabled="isSaving" @click="saveProject" class="save-button">
                <span v-if="!isSaving">Create</span>
                <span v-else>Creating...</span>
              </button>
            </div>
            <p v-if="errorMessage" style="color:red" class="error-message">{{ errorMessage }}</p>
          </div>
        </div>
        <div v-if="deleteModal" class="modal-overlay">
          <div class="modal">
            <h3>Delete Task</h3>
              <h6>Are you sure you want to delete?</h6>
              <div class="modal-footer">
                <button @click="closeDeleteModal" class="cancel-button">Cancel</button>
                <button :disabled="isDeleting" @click="deleteProject" class="delete-button-modal">
                  <span v-if="!isDeleting">Delete</span>
                  <span v-else>Deleting...</span>
                </button>
              </div>
              <p v-if="errorMessage" style="color:red" class="error-message">{{ errorMessage }}</p>
          </div>
        </div>
        <!----- Project ----->
        <div class="projects">
          <!-- v-for to iterate over projects -->
          <div v-for="(project, index) in projectList" :key="index" class="project">
            <div class="project-header">
              <i class="fa-solid fa-trash delete-icon" @click="openDeleteModal(project.projectID)"></i>
            </div>
            <img :src="project.coverImage" :alt="project.title" @click="goToProject(project.projectID) " style="cursor:pointer;"/>
            <div class="project-info">
              <h2>{{ project.title }}</h2>
              <p>{{ project.desc }}</p>
            </div>
          </div>
        </div>
    </div>
    <!-- Right Preview -->
    <div class="preview-section" v-if="!isMobile"  style="margin-right:20px;margin-top:20px">
      <div class="preview-card">
       <h3 class="preview-title">Progress Chart</h3>
          <div v-for="(data, key) in progressData" :key="key" class="progress-item">
            <div class="progress-label">
              <span>{{ data.title }}</span>
              <span>{{ data.progress.toFixed(2) }}%</span>
            </div>
            <div class="progress-bar">
              <div
                class="progress-fill"
                :style="{ width: data.progress + '%' }"
              ></div>
            </div>
          </div>
      </div>
    </div>
  
  </div>
</template>
<script>
import axios from "axios";
import Navbar from "../components/navbar.vue";
import router from "@/router";
import {
  getAuth,
  onAuthStateChanged
} from "firebase/auth";
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      isNavbarVisible :true,
      defaultPlaceholder: "https://via.placeholder.com/150", // Placeholder image
      accID: "",
      response: "",
      id_token: "",
      apiURL: "https://backend-updateuser-project-553185792079.us-central1.run.app",
      projectCounter: 0, 
      projectList: [],
      isMobile: false, // Detect mobile view
      isModalOpen: false,
      projectTitle:"",
      projectDesc:"",
      projectDesignImages:[
      "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FProjectTemplate1.jpg?alt=media&token=977baaf5-d0e7-44e4-95e8-48645eabbdd2",
      "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FProjectTemplate2.jpg?alt=media&token=9f563541-fb6f-4bf1-b03d-d6c317b1a5f1",
      "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FProjectTemplate3.jpg?alt=media&token=3eb83ba7-c25a-4b5b-90d9-b146e42921e9",
      "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FProjectTemplate4.jpg?alt=media&token=d7533c1b-19f5-459e-af00-18063e952a62",
      "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FProjectTemplate5.jpg?alt=media&token=6880b3c5-1836-4365-b36e-1bfee6aec116",
      "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FProjectTemplate6.jpg?alt=media&token=d538ce12-1097-4d3c-b6bd-b8e43e2c7333",
      "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FProjectTemplate7.jpg?alt=media&token=410ca33b-0fc5-4b3b-beda-d5b74249a25e",
      "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FProjectTemplate8.jpg?alt=media&token=3b860879-e3b7-4fcc-8d43-3debc1b6767e",
      "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FProjectTemplate9.jpg?alt=media&token=b3916c93-70aa-49e1-8572-835e286bb700",
      "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FProjectTemplate10.jpg?alt=media&token=623dca80-9f29-45bc-94c8-000a6b251fd0"
      ],
      weddingProjectDesignImages:[
        "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FWeddingProject1.jpg?alt=media&token=a9f464e5-efb1-4ffe-97d9-3b28b7372ce7",
        "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FWeddingProject2.jpg?alt=media&token=0ea2c088-773c-4a3b-94c9-19fda5c98399",
        "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FWeddingProject3.jpg?alt=media&token=eadc558d-a417-47e2-a3ef-6fe2d83af1cb",
        "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FWeddingProject4.jpg?alt=media&token=34cc0361-fb06-400f-a944-89b0eb91cc02",
        "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FWeddingProject5.jpg?alt=media&token=6d86dcf3-fd3a-4299-afb2-f5fbefdddd5a",
        "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FWeddingProject6.jpg?alt=media&token=5274b024-f7f3-4321-a685-f182c603aa2e",
        "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FWeddingProject7.jpg?alt=media&token=c6871669-0f5e-4547-84be-a4d34e022b92",
        "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FWeddingProject8.jpg?alt=media&token=7d80dfe0-b6e5-460d-8587-23d8bdcf40a1",
        "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FWeddingProject9.jpg?alt=media&token=89e39f7e-26bd-4b4a-9271-28ee2656b74e",
        "https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/DesignTemplates%2FprojectTemplates%2FWeddingProject10.jpg?alt=media&token=2389401a-4911-43c7-948a-9f2d1da9da5f",
      ],
      tasks: [],
      interiorTasks:["Demolition & Haulage Work", "Masonry Works", "Electrical Works","Plumbing Works","Glass Works",
      "Gas Works","Carpentry Works","Air Con Works", "Gypsum Board works", "Painting Works", "Vinyl Floor works","Worktop Works", "Inspection" ], 
      weddingTask:["Budget Planning", "Venue Selection","Vendor Coordination", "Wedding Theme and Design", 
      "Guest List & Invitations", "Legal & Permits", "Seating Arrangements","Food & Beverage Selection", "Logistics","Transportation", "Post-Wedding Cleanup"],
      selectedTasks: [],       // Array to hold selected tasks
      startDate:"",
      projectID:"",
      houseSize:"",
      progressData:{},
      isSaving: false, // State to show spinner and disable button
      errorMessage: "", // State to hold error message
      otherTask:"",
      deleteProjectInfo:"",
      deleteModal:false,
      isDeleting:false,
      industry:"",
      endDate:""


    };
  },
  methods: {
    onIndustryChange(){
      this.selectedTasks=[]
      if (this.industry=="interiorDesign"){
        this.tasks = this.interiorTasks
      }
      else if (this.industry=="weddingPlanner"){
        this.tasks = this.weddingTask
      }


    },
    toggleTask(index) {
      const task = this.tasks[index];
      if (this.selectedTasks.includes(task)) {
        // Remove the task if it's already selected
        this.selectedTasks = this.selectedTasks.filter(t => t !== task);
      } else {
        // Add the task if it's not selected
        this.selectedTasks.push(task);
      }
    },
    async saveProject(){
      this.isSaving = true; 
      this.errorMessage = "";
      if(this.industry=="weddingPlanner"){
        var randomNumber = Math.floor(Math.random() * this.weddingProjectDesignImages.length);
        var projectDesign = this.weddingProjectDesignImages[randomNumber];
      }else{
        randomNumber = Math.floor(Math.random() * this.projectDesignImages.length);
        projectDesign = this.projectDesignImages[randomNumber];
      }
      var allTask = this.selectedTasks.join(",") +","+this.otherTask
      await axios
        .post(this.apiURL + "/createProject", {
          userID: this.accID,
          title: this.projectTitle,
          projectID: this.projectID,
          desc:this.projectDesc,
          coverImage: projectDesign,
          houseSize:this.houseSize,
          startDate:this.startDate,
          endDate:this.endDate,
          projectType:this.industry,
          designTasks: allTask,

          id_token: this.id_token,
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return;
          }
          if (response.status==428){
             this.errorMessage = response.message
          }
          var projectID = this.projectID
          this.projectTitle=""
          this.projectID=""
          this.projectDesc=""
          this.houseSize =""
          this.startDate=""
          this.selectedTasks=[]
          this.$router.push({
            path: "/projectInfo",
            query: {
              projectid: projectID, // Replace `this.projectID` with your dynamic value
            },
            });
          
        })
        .catch((error) => {
          if (error.response && error.response.status === 428) {
            this.errorMessage = "Project ID Have been used please try something else";
          } else {
            console.error("Error saving project:", error);
            this.errorMessage = "Failed to save project. Please try again.";
          }
        })
        .finally(()=>{
        this.isSaving = false; // Reset spinner and enable button
      });
      return "OK"
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.projectTitle=""
      this.projectID=""
      this.projectDesc=""
      this.houseSize =""
      this.startDate=""
      this.selectedTasks=[]
      this.industry=""
      this.endDate=""
    },
    openDeleteModal(info){
      this.deleteModal = true;
      this.deleteProjectInfo = info;
    },
    closeDeleteModal(){
      this.deleteProjectInfo=""
      this.deleteModal = false;
    },
    goToProject(projectid) {
      this.$router.push({
        path: "/projectInfo",
        query: {
          projectid: projectid, 
        },
      });
    },
    async checkAuthState() {
      const auth = getAuth();
      return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            try {
              const idToken = await user.getIdToken();
              this.id_token = idToken;
              resolve(); // Resolve after id_token is set
            } catch (error) {
              console.error("Error fetching ID token:", error);
              reject(error); // Reject in case of errors
            }
          } else {
            router.push("/");
            reject("No user authenticated");
          }
        });
      });
  },
      checkScreenSize() {
      this.isMobile = window.innerWidth <= 768; // Set breakpoint for mobile
      if (this.isMobile) {
        this.isPreviewVisible = false; // Hide preview on mobile
        this.isNavbarVisible = false;
        this.$refs.navbar.toggleNavbar(false);
      }
    },
    adjustMainContent(isVisible) {
      this.isNavbarVisible = isVisible; 
    },
    async deleteProject(){
        this.isDeleting = true
        await axios
        .post(this.apiURL + "/deleteProject", {
          userID: this.accID,
          projectID:this.deleteProjectInfo,
          id_token: this.id_token,
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return;
          }
         this.refreshProjecList();
         this.isDeleting = false
         this.closeDeleteModal();

         
        })
        .catch(() => {
        });
        return "OK"
      },
     async refreshProjecList(){
        axios.get(this.apiURL + "/getAllProjectInfo/"+this.accID, {
            headers: {
              Authorization: this.id_token
            },
          })
          .then((response) => {
            
            if (response.status == 401) {
              alert("Login Expire! Please Login Again");
              router.push("/");
              return ;
            }
            var currProjectList= response.data.message;
            this.projectList = currProjectList;
            this.projectCounter = currProjectList.length; 
          })
          .catch(() => {
            // Handle error
            // return "error"
          });
            // return "OK"
    axios.get(this.apiURL + "/calculateTodo/"+this.accID, {
            headers: {
              Authorization: this.id_token
            },
          })
          .then((response) => {
            if (response.status == 401) {
              alert("Login Expire! Please Login Again");
              router.push("/");
              return ;
            } 
            const todos = response.data.message; // Example response: { '123': { total: 11, done: 0 }, '456': { total: 8, done: 5 } }
            this.progressData = todos;

            

            
          })
          .catch(() => {
            // Handle error
            // return "error"
          });
            // return "OK"
      }
    

  },
  async mounted() {

    this.accID = this.$store.getters.getUserId;
    await this.checkAuthState();
    this.checkScreenSize(); // Check on page load
    window.addEventListener("resize", this.checkScreenSize); // Watch for window resize
    window.addEventListener("touchmove", (event) => {
      if (this.isMobile) {
        event.stopPropagation();
      }
    });
    axios.get(this.apiURL + "/getAllProjectInfo/"+this.accID, {
            headers: {
              Authorization: this.id_token
            },
          })
          .then((response) => {
            
            if (response.status == 401) {
              alert("Login Expire! Please Login Again");
              router.push("/");
              return ;
            }
            var currProjectList= response.data.message;
            this.projectList = currProjectList;
            this.projectCounter = currProjectList.length; 
          })
          .catch(() => {
            // Handle error
            // return "error"
          });
            // return "OK"
    axios.get(this.apiURL + "/calculateTodo/"+this.accID, {
            headers: {
              Authorization: this.id_token
            },
          })
          .then((response) => {
            if (response.status == 401) {
              alert("Login Expire! Please Login Again");
              router.push("/");
              return ;
            } 
            const todos = response.data.message; // Example response: { '123': { total: 11, done: 0 }, '456': { total: 8, done: 5 } }
            this.progressData = todos;

            

            
          })
          .catch(() => {
            // Handle error
            // return "error"
          });
            // return "OK"
            
  }
};
</script>
<style scoped>
.main-screen {
  display: flex;
  /* height: 100vh; */
  gap: 20px;
}

.navbar-section {
  width: 250px;
  text-align: left;
}

.main-content {
  height: 100vh;
  flex: 2;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow-y: auto;
  text-align: left !important; /* Override Bootstrap */
  justify-content: flex-start !important; /* Ensure left alignment */
  align-items: flex-start !important; /* For flex containers */
}

.profile-picture img {
  max-width: 100px;
  border-radius: 50%;
  /* display: block; */
  /* margin: 0 auto 10px; */
}

/* .profile-picture input {
  display: block;
  margin: 10px auto;
} */

.sections .section {
  margin-bottom: 20px;
}

.preview-section {
  width: 350px; /* Matches the card width */
  height: auto; /* Adjusts to the content height */
  z-index: 1000; /* Ensures it stays above other elements */
  background-color: transparent; /* Transparent background to blend with the page */
}
.navbar-section {
  transition: width 0.3s ease;
  overflow: hidden; /* Prevent content overflow when hidden */
}

.navbar-section.hidden {
  width: 0; /* Collapse navbar */
}
.main-content {
  flex: 2;
  transition: flex 0.3s ease; /* Smooth adjustment */
}

.main-content.full-width {
  flex: 1; /* Take over the space */
}
.main-screen{
  background-color: #f9f9f9;
  text-align: left !important; /* Override Bootstrap */
  justify-content: flex-start !important; /* Ensure left alignment */
  align-items: flex-start !important; /* For flex containers */
}

/* Spinner Style */
.spinner {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  }
 
.add-project {
  /* padding: 10px 15px; */
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  margin-bottom: -7px;
  margin-left: 5px
}

.add-project:hover {
  background-color: #f0f0f0;
}
.header {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 24px;
  margin: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.modal {
  background: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 700px;
  width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
  display: initial;
  position: relative;
  height: auto;
  overflow-y: auto;
  max-height: 80%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-footer {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 20px;
}

.cancel-button,
.save-button,
.delete-button-modal {
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button {
  background-color: #f0f0f0;
}

.save-button {
  background-color: #63D58F;
  color: white;
}
.delete-button-modal{
  background-color:#E53B46 ;
  color: white;
}


.projects {
  /* display: flex; */
  flex-wrap: wrap;
  gap: 20px;
}

.project {
  /* width: calc(33.33% - 20px);  */
  position: relative; /* Make the parent relative for positioning child elements */
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.project-header {
  position: absolute;
  top: 10px;
  right: 10px;
}

.delete-icon {
  font-size: 18px;
  color: #E53B46; /* Trash icon color */
  cursor: pointer;
  transition: color 0.2s ease;
}

.delete-icon:hover {
  color: #E53B46; /* Hover effect */
}

.project img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  display: block;
  border-radius: 15px;
}

.project-info {
  position: absolute; /* Position the info on top of the image */
  bottom: 0; /* Stick to the bottom of the project container */
  left: 0;
  width: 100%;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.8); /* Transparent white background */
  backdrop-filter: blur(5px); /* Optional frosted glass effect */
  color: black; /* Text color to contrast with the background */
  border-radius: 15px;

}

.project-info h2 {
  font-size: 18px;
  margin: 0;
}

.project-info p {
  font-size: 14px;
  color: gray;
  margin: 5px 0 0;
}


.task-toggle-button {
  border: 2px solid #5EB2EE; /* Default border */
  background-color: white; /* Default background */
  color: 5EB2EE; /* Default text color */
  font-size: 14px; /* Adjust font size */
  padding: 10px; /* Add padding */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  text-align: center;
  width: 100%; /* Make the button fill its container */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.task-toggle-button.active {
  background-color: #5EB2EE; /* Highlight active tasks */
  color: white; /* Change text to white when active */
}

.task-toggle-button:hover {
  background-color: lightblue; /* Light blue on hover */
  color: #5EB2EE; /* Blue text on hover */
}
.progress-chart-container {
  max-width: 600px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

h3 {
  text-align: center;
  margin-bottom: 20px;
}

.progress-item {
  margin-bottom: 15px;
}

.progress-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 14px;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: #63D58F;
  width: 0%;
  transition: width 0.3s ease-in-out;
  border-radius: 10px 0 0 10px;
}

::-webkit-scrollbar {
            width: 12px;
        }

::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
    box-shadow: inset 0 0 6px rgba(100, 100, 100, 0.3);
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #8b8b8b;
}

</style>