<template>
  <div>
    <!-- Toggle Button -->
    <button class="toggle-btn" @click="toggleNavbar">
            <i v-if="isNavbarOpen" class="fa-solid fa-arrow-left-long"></i>
            <i v-else class="fa-solid fa-bars"></i>
        </button>
    <!-- Navbar -->
    <div :class="['navbar', { 'navbar-open': isNavbarOpen }]" >
        <img src="../assets/TNT_LOGO_W_NAME.png" alt="TNT Logo" style="height:50px" />
        
      <div class="logo">
        

      </div>
      <h5>Profile</h5>
      <ul>
        <li v-for="(item, index) in this.menuProfileItems" :key="index">
          <a :href="item.link">
            <i :class="item.icon"></i> {{ item.name }}
          </a>
        </li>
      </ul>
      <h5>Work</h5>
      <ul>
        <li v-for="(item, index) in menuWorkItems" :key="index">
          <a :href="item.link">
            <i :class="item.icon"></i> {{ item.name }}
          </a>
        </li>
      </ul>
      <h5>Others</h5>
      <ul>
        <li v-for="(item, index) in menuOtherItems" :key="index">
          <a :href="item.link">
            <i :class="item.icon"></i> {{ item.name }}
          </a>
        </li>
      </ul>
      <div class="logout-section">
        <ul>
          <li>
            <a href="#" @click.prevent="handleLogout">
                <i class="fa-solid fa-right-from-bracket"></i>
                Logout
                </a>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";

export default {
  data() {
    return {
      isNavbarOpen: true, // Controls navbar visibility
     menuProfileItems: [
      { name: "Edit Profile", link: "/EditUser", icon: "fa-regular fa-user" },
      { name: "Interface Design", link: "/intefaceDesign", icon: "fa-solid fa-compass-drafting" },
    ],
    menuWorkItems: [
      { name: "Projects", link: "/projectpage", icon: "fa-regular fa-folder" }
    ],
    menuOtherItems: [
      // { name: "Analytics", link: "/analytics", icon: "fa-solid fa-chart-simple" },
      { name: "Reset Password", link: "/resetPassword", icon: "fa-solid fa-lock" },
    ],
    };
  },
  props: {
    
  },
  methods: {
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen; // Toggle navbar
      this.$emit("toggle-navbar", this.isNavbarOpen);
     
    },
    handleLogout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          this.$router.push("/"); // Redirect to login page
        })
        .catch(() => {
          // console.error("Error signing out:", error);
        });
    },
  },
};
</script>

<style scoped>
/* Navbar styles */
.navbar {
  position: fixed;
  top: 0;
  left: -250px; /* Initially hidden */
  width: 250px;
  height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
  padding-top: 80px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  text-align: left !important; /* Override Bootstrap */
  justify-content: flex-start !important; /* Ensure left alignment */
  align-items: flex-start !important; /* For flex containers */
  transition: left 0.3s ease; /* Smooth slide effect */
}

.navbar-open {
  left: 0; /* Slide in */
}

.logo {
  margin-bottom: 30px;
  text-align: left;
}

.logo img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 15px;
}

li a {
  text-decoration: none;
  color: #333;
  font-weight: normal;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
}

li a i {
  margin-right: 10px; /* Space between icon and text */
}

li a:hover {
  color: #000;
  font-weight: bold;
}

.logout-section {
  margin-top: auto;
  text-align: left;
}

.logout-section a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  font-size: 1rem;
}

.logout-section a:hover {
  color: #333;
}

/* Toggle Button */
.toggle-btn {
  position: fixed;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1000; /* Ensure above other content */
}

</style>
