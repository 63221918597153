<template>
  <div class="login-container">
    <div class="gray-box">
      <div class="login-box">
        <div class="logo">
          <img src="../assets/TNT_LOGO_W_NAME.png" alt="TNT Logo" />
        </div>
        <h2>Forget Password</h2>
        <p>Please enter you registered email address.<br/> You will recieve a link to create a new password via email</p>
        <form @submit.prevent="forgetPass">
          <div class="form-group">
            <input
              type="email"
              v-model="forgetEmail"
              placeholder="Enter your email"
              required
            />
          </div>
          <button type="submit" class="btn-login">Reset Password</button
          ><p v-if="response" >{{ response }}</p>
        </form>
        <div class="links">
          <a href="/">Login</a>
          <a href="/signUp">Sign Up</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {
  getAuth,
  sendPasswordResetEmail
} from "firebase/auth";

export default {
  data() {
    return {
      forgetEmail: "",
      response:"",
    };
  },
  methods: {
    forgetPass() {
      const auth = getAuth();
      sendPasswordResetEmail(auth, this.forgetEmail)
        .then(() => {
          this.response = "Password reset email sent successfully!";
        })
        .catch((error) => {
          this.response = `Error: ${error.message}`;
        });
    },
  },
};
</script>

<style scoped>
/* General Container */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
}
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 15px;
  text-align: left;
}

/* Gray Box */
.gray-box {
  width: 600px; /* Larger gray box */
  padding: 40px;
  border-radius: 20px;
  background-color: #d3d3d3; /* Light gray background */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Login Box */
.login-box {
  width: 400px;
  padding: 30px;
  border-radius: 15px;
  /* background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  text-align: center;
  margin: auto; /* Center the login box inside the gray box */
}

/* Logo */
.logo {
  margin-bottom: 20px;
}

.logo img {
  max-width: 50%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Login Heading */
h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

/* Form */
.form-group {
  margin-bottom: 15px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  font-size: 1rem;
}

/* Button */
.btn-login {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-login:hover {
  background-color: #333;
}

/* Links */
.links {
  margin-top: 20px;
}

.links a {
  color: #000;
  text-decoration: none;
  font-size: 0.9rem;
  margin: 0 10px;
}

.links a:hover {
  text-decoration: underline;
}

/* Mobile Responsive Design */
@media screen and (max-width: 480px) {
  .gray-box {
    width: 90%; /* Gray box adjusts for smaller screens */
    padding: 20px;
  }

  .login-box {
    width: 100%; /* Login box remains proportional inside gray box */
    padding: 20px;
  }

  h2 {
    font-size: 1.2rem;
  }

  .form-group input {
    padding: 8px;
    font-size: 0.9rem;
  }

  .btn-login {
    padding: 8px;
    font-size: 0.9rem;
  }

  .links a {
    font-size: 0.8rem;
  }
}
</style>
