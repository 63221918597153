<template>
  <div class="main-screen">
    <!-- Left Navbar -->
    <div :class="['navbar-section', { hidden: !isNavbarVisible }]">
      <Navbar @toggle-navbar="adjustMainContent" />
    </div>

    <!-- Center Main Content -->
    <div :class="['main-content', { 'full-width': !isNavbarVisible }]" style="margin-left: 20px">
      <!-- Reset Password Form -->
      <div class="reset-password-section">
        <h2>Reset Password</h2>
        <p>
          Enter the email associated with your account, and we’ll send an email
          with instructions to reset your password.
        </p>
        <form @submit.prevent="forgetPass">
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="email"
              v-model="email"
              id="email"
              class="form-control"
              placeholder="Email"
              required
            />
          </div>
          <button type="submit" class="btn btn-dark w-100 mt-3">Reset</button>
        </form>
        <!-- Feedback message -->
        <p v-if="response" class="response-message">{{ response }}</p>
      </div>
    </div>

    <!-- Right Preview (Optional, Hidden on Mobile) -->
    <!-- <div class="preview-section" v-if="!isMobile" style="margin-right: 20px; margin-top: 20px">
      <div class="preview-card">
        <h3 class="preview-title">Progress Chart</h3>
        <div v-for="(data, key) in progressData" :key="key" class="progress-item">
          <div class="progress-label">
            <span>{{ data.title }}</span>
            <span>{{ data.progress.toFixed(2) }}%</span>
          </div>
          <div class="progress-bar">
            <div class="progress-fill" :style="{ width: data.progress + '%' }"></div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Navbar from "../components/navbar.vue";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      isNavbarVisible: true,
      email: "",
      response: "",
      isMobile: false,
      progressData: [], // Add your progress data here if needed
    };
  },
  methods: {
    forgetPass() {
      const auth = getAuth();
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          this.response = "Password reset email sent successfully!";
        })
        .catch((error) => {
          this.response = `Error: ${error.message}`;
        });
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768; // Set breakpoint for mobile
      if (this.isMobile) {
        this.isPreviewVisible = false; // Hide preview on mobile
        this.isNavbarVisible = false;
        this.adjustMainContent(false);
      }
    },
    adjustMainContent(isVisible) {
      this.isNavbarVisible = isVisible;
    },
  },
  mounted() {
    window.addEventListener("resize", this.checkScreenSize);
    window.addEventListener("touchmove", (event) => {
      if (this.isMobile) {
        event.stopPropagation();
      }
    });
    this.checkScreenSize(); // Initial check
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style scoped>
.main-screen {
  display: flex;
  gap: 20px;
}


.main-content {
  transition: all 0.3s ease;
  flex: 1; /* Allow content to take up remaining space */
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 10px;
  overflow-y: auto;
}

.navbar-section {
  width: 250px; /* Default navbar width */
  transition: all 0.3s ease;
}

.navbar-section.hidden {
  width: 0; /* Set navbar width to 0 when hidden */
}

.main-content.full-width {
  margin-left: 0; /* Remove any margin when navbar is hidden */
  flex: 1; /* Full width */
}
.reset-password-section {
  /* margin-top: 20px;
  max-width: 400px; */
  padding: 20px;
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  background-color: #fff;
}

.response-message {
  margin-top: 15px;
  font-size: 14px;
  color: green;
}

.progress-item {
  margin-bottom: 15px;
}

.progress-label {
  display: flex;
  justify-content: space-between;
}

.progress-bar {
  height: 8px;
  background-color: #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: #007bff;
}
</style>
