<template>
  <!-- <div v-if="imageId" class="image-preview-container"> -->
    <img :src="getImageSrc(this.imageId)" alt="Preview Image" class="preview-image" />
  <!-- </div> -->
</template>

<script>
export default {
  props: {
    imageId: {
      type: String,
      required: true, // The current image ID
    },
  },
  data() {
    return {
      imageMap: {
        "FcWGfuVirJRo2B51Tr5l":  require("@/assets/TemplateDesigns/hydroflux_darkmode_interface.png"),
        "M2ndkvBJc1fc67Ts7YJv":  require("@/assets/TemplateDesigns/Interface_3.png"),
        "bvWHcqOYNiOtEbUx2FQV":  require("@/assets/TemplateDesigns/hydroflux_lightmode_interface.png"),
        "ditK7TpNpjG3sXEwGxBy":  require("@/assets/TemplateDesigns/Interface_2.png"),
        "iLjAw41CX5E9YjPgA0KA":  require("@/assets/TemplateDesigns/Interface_1.png"),
        "keLb1cIMYUhbU0R3Jq0m":  require("@/assets/TemplateDesigns/cq_interface.png"),
        "kidYZLj37jaLkmEitd2T":  require("@/assets/TemplateDesigns/vast_interface.png"),
        "wVqWyTugalSFyRFihwGe":  require("@/assets/TemplateDesigns/vast_team_interface.png")
      },
    };
  },
  methods: {
    getImageSrc(id) {
      return this.imageMap[id] || "path/to/default.png"; // Return the image path or a default placeholder
    },
    updatePreview() {
        
    },
  },
  watch: {
    imageId(newId) {
        this.updatePreview(newId); // Call a method to update the preview
    },
},
};
</script>
<style scoped>
  .image-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.preview-image {
   width: 100%; /* Make the image take up the full width of the container */
  height: 100%; /* Make the image take up the full height of the container */
  object-fit: contain; /* Ensure the image scales without cropping */
  border-radius: 10px; /* Optional */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional */
  object-fit: cover; /* Ensure proper scaling */
}

</style>

