<template>
  <div class="main-screen">
    <!-- Left Navbar -->
    <div  :class="['navbar-section', { hidden: !isNavbarVisible }]">
      <Navbar ref="navbar"
        
        @toggle-navbar="adjustMainContent"
      />
    </div>

    <!-- Center Main Content -->
    <div :class="['main-content', { 'full-width': !isNavbarVisible }]" style="margin-left:20px">
      <h2>Edit Profile</h2>

      <!-- Profile Picture -->
      <div class="profile-picture">
          <!-- Circular Placeholder -->
          <div class="image-container">
            <img :src="profileImg || defaultPlaceholder" alt="Profile" />
          
            <!-- Button and Info Text -->
            <div class="upload-actions">
              <button class="upload-button" @click="triggerUpload">Upload new photo</button>
              <p class="upload-info">
                At least 800x800 px recommended.<br />
                JPG or PNG is allowed.
              </p>
            </div>
          </div>
          <!-- Hidden File Input -->
          <input 
            type="file" 
            ref="fileInput" 
            class="file-input" 
            accept="image/*" 
            @change="onImageChange"
          />
          <!-- Cropper custom-custom-modal -->
         <div v-if="cropping" class="custom-custom-modal">
            <div class="custom-custom-modal-content">
              <h3>Crop Your Image</h3>
              <img ref="cropImage" :src="imageSrc" alt="Crop Preview" class="crop-img" />
              <div class="custom-custom-modal-actions">
                <button class="crop-button" @click="cropImage">Crop & Save</button>
                <button class="cancel-button" @click="cancelCrop">Cancel</button>
              </div>
            </div>
          </div>

          <!-- Overlay -->
          <div v-if="cropping" class="custom-custom-modal-overlay" @click="cancelCrop"></div>
        </div>
        <hr class="divider" />
         <!-- Personal Info Section -->
          <div class="personal-info-card">
            <!-- Header -->
            <div class="card-header">
              <h3 class="title">Personal Info</h3>
              <button v-if="profileEdit  && !profileLoading" class="save-button" @click="toggleProfile(false)">
                <i class="fa-solid fa-pen-to-square"></i> Edit
              </button>
              <button v-if="!profileEdit" :disabled="profileLoading" class="save-button" @click="toggleProfile(true)">
                <template v-if="!profileLoading">
                  <i class="fa-regular fa-floppy-disk"></i> Save
                </template>
                <div v-else class="spinner"></div>

              </button>
            </div>

            <!-- Content -->
            <div class="info-content">
              <div class="info-row">
                <div class="info-column">
                  <span class="info-label">Full Name</span>
                  <span v-if="profileEdit" class="info-value">{{name}}</span>
                  <input 
                    v-if="!profileEdit"
                    v-model="name" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your full name" 
                  />
                </div>
                <div class="info-column">
                  <span class="info-label">Email</span>
                  <span v-if="profileEdit" class="info-value">{{email}}</span>
                  <input 
                    v-if="!profileEdit"
                    v-model="email" 
                    type="email" 
                    class="info-input"
                    placeholder="Enter your email" 
                  />
                </div>
              </div>

              <div class="info-row">
                <div class="info-column">
                  <span class="info-label">Company Name</span>
                  <span v-if="profileEdit" class="info-value">{{companyName}}</span>
                  <input 
                    v-if="!profileEdit"
                    v-model="companyName" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your Company Name" 
                  />
                </div>
                <div class="info-column">
                  <span class="info-label">Position/Role</span>
                  <span v-if="profileEdit" class="info-value">{{role}}</span>
                  <input 
                    v-if="!profileEdit"
                    v-model="role" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your role" 
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Contact Info Section -->
          <div class="personal-info-card">
            <!-- Header -->
            <div class="card-header">
              <h3 class="title">Contact Info</h3>
              <button v-if="contactEdit  && !contactLoading" class="save-button" @click="toggleContact(false)">
                <i class="fa-solid fa-pen-to-square"></i> Edit
              </button>
              <button v-if="!contactEdit" :disabled="contactLoading" class="save-button" @click="toggleContact(true)">
                <template v-if="!contactLoading">
                  <i class="fa-regular fa-floppy-disk"></i> Save
                </template>
                <div v-else class="spinner"></div>

              </button>
            </div>

            <!-- Content -->
            <div class="info-content">
              <div class="info-row">
                <div class="info-column">
                  <span class="info-label">Mobile No.</span>
                  <span v-if="contactEdit" class="info-value">{{contactNumber}}</span>
                  <input 
                    v-if="!contactEdit"
                    v-model="contactNumber" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your mobile number" 
                  />
                </div>
                <div class="info-column">
                  <span class="info-label">Office No.</span>
                  <span v-if="contactEdit" class="info-value">{{officeNumber}}</span>
                  <input 
                    v-if="!contactEdit"
                    v-model="officeNumber" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your office number" 
                  />
                </div>
                <div class="info-column">
                  <span class="info-label">Fax No.</span>
                  <span v-if="contactEdit" class="info-value">{{faxNumber}}</span>
                  <input 
                    v-if="!contactEdit"
                    v-model="faxNumber" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your fax number" 
                  />
                </div>
              </div>

              <div class="info-row">
                <div class="info-column">
                  <span class="info-label">Telegram Username</span>
                  <span v-if="contactEdit" class="info-value">{{tele}}</span>
                  <input 
                    v-if="!contactEdit"
                    v-model="tele" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your Telegram Username" 
                  />
                </div>
                <div class="info-column">
                  <span class="info-label">WhatsApp No.</span>
                  <span v-if="contactEdit" class="info-value">{{whatsapp}}</span>
                  <input 
                    v-if="!contactEdit"
                    v-model="whatsapp" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your WhatsApp No." 
                  />
                </div>
                <div class="info-column">
                </div>
              </div>
            </div>
          </div>

          <!-- Social Info Section -->
          <div class="personal-info-card">
            <!-- Header -->
            <div class="card-header">
              <h3 class="title">Social Media</h3>
              <button v-if="socialEdit  && !socialLoading" class="save-button" @click="toggleSocial(false)">
                <i class="fa-solid fa-pen-to-square"></i> Edit
              </button>
              <button v-if="!socialEdit" :disabled="socialLoading" class="save-button" @click="toggleSocial(true)">
                <template v-if="!socialLoading">
                  <i class="fa-regular fa-floppy-disk"></i> Save
                </template>
                <div v-else class="spinner"></div>

              </button>
            </div>

            <!-- Content -->
            <div class="info-content">
              <div class="info-row">
                <div class="info-column">
                  <span class="info-label">Facebook</span>
                  <span v-if="socialEdit" class="info-value">{{fb}}</span>
                  <input 
                    v-if="!socialEdit"
                    v-model="fb" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your facebook link" 
                  />
                </div>
                <div class="info-column">
                  <span class="info-label">Instagram</span>
                  <span v-if="socialEdit" class="info-value">{{insta}}</span>
                  <input 
                    v-if="!socialEdit"
                    v-model="insta" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your instagram link" 
                  />
                </div>
              </div>

              <div class="info-row">
                <div class="info-column">
                  <span class="info-label">X</span>
                  <span v-if="socialEdit" class="info-value">{{twitter}}</span>
                  <input 
                    v-if="!socialEdit"
                    v-model="twitter" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your X link" 
                  />
                </div>
                <div class="info-column">
                  <span class="info-label">Linkedin</span>
                  <span v-if="socialEdit" class="info-value">{{linkedin}}</span>
                  <input 
                    v-if="!socialEdit"
                    v-model="linkedin" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your linkedin link" 
                  />
                </div>
              </div>

              <div class="info-row">
                <div class="info-column">
                  <span class="info-label">Tiktok</span>
                  <span v-if="socialEdit" class="info-value">{{tiktok}}</span>
                  <input 
                    v-if="!socialEdit"
                    v-model="tiktok" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your tiktok link" 
                  />
                </div>
                <div class="info-column">
                  <span class="info-label">Snapchat</span>
                  <span v-if="socialEdit" class="info-value">{{snapchat}}</span>
                  <input 
                    v-if="!socialEdit"
                    v-model="snapchat" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your snapchat link" 
                  />
                </div>
              </div>

              <div class="info-row">
                <div class="info-column">
                  <span class="info-label">Youtube</span>
                  <span v-if="socialEdit" class="info-value">{{youtube}}</span>
                  <input 
                    v-if="!socialEdit"
                    v-model="youtube" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your youtube link" 
                  />
                </div>
                <div class="info-column">
                  <span class="info-label">Website Link</span>
                  <span v-if="socialEdit" class="info-value">{{website}}</span>
                  <input 
                    v-if="!socialEdit"
                    v-model="website" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your website link" 
                  />
                </div>
              </div>

              <div class="info-row">
                <div class="info-column">
                  <span class="info-label">Facebook Review</span>
                  <span v-if="socialEdit" class="info-value">{{fbReview||'-'}}</span>
                  <input 
                    v-if="!socialEdit"
                    v-model="fbReview" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your facebook review link" 
                  />
                  <small class="form-text text-muted" style="color:grey;">Only for exclusive template</small>
                </div>
                <div class="info-column">
                  <span class="info-label">Google Review</span>
                  <span v-if="socialEdit" class="info-value">{{googleReview||'-'}}</span>
                  <input 
                    v-if="!socialEdit"
                    v-model="googleReview" 
                    type="text" 
                    class="info-input"
                    placeholder="Enter your google review link" 
                  />
                  <small class="form-text text-muted" style="color:grey;">Only for exclusive template</small>
                </div>
              </div>

            </div>
          </div>

           <!-- Address Info Section -->
          <div class="personal-info-card">
            <!-- Header -->
            <div class="card-header">
              <h3 class="title">Location</h3>
              <button v-if="addresseEdit  && !addressLoading" class="save-button" @click="toggleAddress(false)">
                <i class="fa-solid fa-pen-to-square"></i> Edit
              </button>
              <button v-if="!addresseEdit" :disabled="addressLoading" class="save-button" @click="toggleAddress(true)">
                <template v-if="!addressLoading">
                  <i class="fa-regular fa-floppy-disk"></i> Save
                </template>
                <div v-else class="spinner"></div>

              </button>
            </div>

            <!-- Content -->
            <div class="info-content">
              <div class="info-row">
                <div class="info-column">
                  <!-- <span class="info-label">Address</span> -->
                  
                  <span v-if="addresseEdit" class="info-value"><i class="fa-solid fa-location-dot" style="margin-right:10px"></i>{{address}}</span>
                  <div  v-if="!addresseEdit" class="icon-input-container">
                    <i class="fa-solid fa-location-dot"></i>
                    <input 
                      v-model="address" 
                      type="text" 
                      class="info-input"
                      placeholder="Enter your address" 
                      
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Project Info Section -->
          <div class="personal-info-card">
            <!-- Header -->
            <div class="card-header">
              <h3 class="title">Project Status</h3>
              <button v-if="projectStatusEdit  && !projectStatusLoading" class="save-button" @click="toggleProjectStatus(false)">
                <i class="fa-solid fa-pen-to-square"></i> Edit
              </button>
              <button v-if="!projectStatusEdit" :disabled="projectStatusLoading" class="save-button" @click="toggleProjectStatus(true)">
                <template v-if="!projectStatusLoading">
                  <i class="fa-regular fa-floppy-disk"></i> Save
                </template>
                <div v-else class="spinner"></div>

              </button>
            </div>

            <!-- Content -->
            <div class="info-content">
              <div class="info-row">
                <div class="info-column">
                  <!-- <span class="info-label">Address</span> -->
                  
                  <!-- <span v-if="projectStatusEdit" class="info-value"  style="white-space: pre-line;:">
                    {{description}}
                    </span> -->
                  
                  
                    <div class="form-check form-switch">
                      <label class="form-check-label" for="flexSwitchCheckChecked">
                        {{ projectStatus ? "Active" : "Inactive" }}
                      </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        v-model="projectStatus"
                        :disabled="projectStatusEdit"
                      />
                      
                    </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Description Info Section -->
          <div class="personal-info-card">
            <!-- Header -->
            <div class="card-header">
              <h3 class="title">Description</h3>
              <button v-if="descriptionEdit  && !descriptionLoading" class="save-button" @click="toggleDescription(false)">
                <i class="fa-solid fa-pen-to-square"></i> Edit
              </button>
              <button v-if="!descriptionEdit" :disabled="descriptionLoading" class="save-button" @click="toggleDescription(true)">
                <template v-if="!descriptionLoading">
                  <i class="fa-regular fa-floppy-disk"></i> Save
                </template>
                <div v-else class="spinner"></div>

              </button>
            </div>

            <!-- Content -->
            <div class="info-content">
              <div class="info-row">
                <div class="info-column">
                  <!-- <span class="info-label">Address</span> -->
                  
                  <span v-if="descriptionEdit" class="info-value"  style="white-space: pre-line;:">
                    {{description}}
                    </span>
                  
                  
                    <textarea 
                      v-if="!descriptionEdit"
                      v-model="description" 
                      type="text" 
                      class="info-input"
                      placeholder="Enter your Description" 
                    />
                </div>
              </div>
            </div>
          </div>
        

          <!-- Accreditation Info Section -->
          <div class="personal-info-card">
            <!-- Header -->
            <div class="card-header">
              <h3 class="title">Accreditation</h3>
             <button v-if="accreditationEdit  && !accreditationLoading" class="save-button" @click="toggleAccreditation(false)">
                <i class="fa-solid fa-pen-to-square"></i> Edit
              </button>
              <button v-if="!accreditationEdit" :disabled="accreditationLoading" class="save-button" @click="toggleAccreditation(true)">
                <template v-if="!accreditationLoading">
                  <i class="fa-regular fa-floppy-disk"></i> Save
                </template>
                <div v-else class="spinner"></div>

              </button>
               
            </div>

            <!-- Content -->
            <div class="info-content">
              <div class="info-row">
                <div class="info-column">
                  <!-- <span class="info-label">Address</span> -->
                  
                  <span v-if="accreditationEdit" class="info-value"  style="white-space: pre-line;:">
                    {{additionalInfo}}
                    </span>
                    <textarea 
                      v-if="!accreditationEdit"
                      v-model="additionalInfo" 
                      type="text" 
                      class="info-input"
                      placeholder="Enter your Accreditation" 
                    />
                </div>
              </div>
              <small class="form-text text-muted" style="color:grey;">Only for exclusive template</small>
              
            </div>
          </div>

          <!-- Resume Info Section -->
          <div class="personal-info-card">
            <!-- Header -->
            <div class="card-header">
              <h3 class="title">Resume</h3>
             <button v-if="resumeEdit  && !resumeLoading" class="save-button" @click="toggleResume(false)">
                <i class="fa-solid fa-pen-to-square"></i> Edit
              </button>
              <button v-if="!resumeEdit" :disabled="resumeLoading" class="save-button" @click="toggleResume(true)">
                <template v-if="!resumeLoading">
                  <i class="fa-regular fa-floppy-disk"></i> Save
                </template>
                <div v-else class="spinner"></div>

              </button>
               
            </div>

            <!-- Content -->
            <div class="info-content">
              <div class="info-row">
                <div class="info-column">
                  <!-- <span class="info-label">Address</span> -->
                  
                  <span v-if="resumeEdit" class="info-value"  style="white-space: pre-line;:">
                    <i class="fa-solid fa-file"></i>
                    {{pdfName}}
                    </span>
                    <div v-if="!resumeEdit">
                    <div class="row">
                      <div class="col">
                      <input 
                        type="file" 
                          id="pdfFile"
                          class="form-control profile"
                          accept="application/pdf,application/octet-stream"
                          ref="fileInput"
                          style="flex-grow: 1;"
                      />
                      </div>
                       <!-- Display Current File Name -->
                        <div class="col" v-if="!(this.currPDFFile === undefined || this.currPDFFile===null || this.currPDFFile === '')">
                          <input
                              type="checkbox" 
                              class="btn-check" 
                              id="btn-check-outlined" 
                              autocomplete="off"
                              @click="pdfChecker = !pdfChecker">
                          <label 
                              class="btn btn-outline-danger" 
                              for="btn-check-outlined">Remove</label>
                        </div>
                    </div>
                    </div>

                </div>
              </div>
            </div>
          </div>


          <!-- Resume Info Section -->
          <div class="personal-info-card">
            <!-- Header -->
            <div class="card-header">
              <h3 class="title">Your Highlights</h3>
             <button v-if="galleryEdit  && !galleryLoading" class="save-button" @click="toggleGallery(false)">
                <i class="fa-solid fa-pen-to-square"></i> Edit
              </button>
              <button v-if="!galleryEdit" :disabled="galleryLoading" class="save-button" @click="toggleGallery(true)">
                <template v-if="!galleryLoading">
                  <i class="fa-regular fa-floppy-disk"></i> Save
                </template>
                <div v-else class="spinner"></div>

              </button>
               
            </div>

            <!-- Content -->
            <div class="info-content">
              <div class="info-row">
                <div class="info-column">
                    <label for="imgGallery1" class="form-label">Highlight 1</label>
                    <!-- Image Preview Section with Cross Button -->
                    <div v-if="galleryEdit  && !galleryLoading" >
                      <div v-if="imagePreview['imageGallery1Preview']" class="image-preview-container position-relative">
                        <img :src="imagePreview['imageGallery1Preview']" class="img-thumbnail" alt="Image preview" />
                      </div>
                      <div v-else class="image-upload-box">
                        <label for="imgGallery1" class="upload-placeholder">
                          <span class="placeholder-plus">+</span>
                        </label>
                        <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
                      </div>
                    </div>
                    <div v-if="!galleryEdit">
                      <div v-if="imagePreview['imageGallery1Preview']" class="image-preview-container position-relative">
                        <img :src="imagePreview['imageGallery1Preview']" class="img-thumbnail" alt="Image preview" />
                        <button  class="btn-close position-absolute top-0 end-0" @click="removeImage('imageGallery1Preview')"></button>
                      </div>
                      <!-- Upload Box as Input -->
                      <div v-else class="image-upload-box" style="cursor:pointer">
                        <input 
                          type="file" 
                          id="imgGallery1" 
                          class="form-control-file"
                          accept="image/png, image/gif, image/jpeg"
                          @change= "event => handleFileChange2(event, 'imageGallery1Preview')"
                          hidden>
                        <label for="imgGallery1" class="upload-placeholder">
                          <span class="placeholder-plus">+</span>
                        </label>
                        <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
                      </div>
                    </div>
                </div>
                <div class="info-column">
                  <label for="imgGallery2" class="form-label">Highlight 2</label>
                    <div v-if="galleryEdit  && !galleryLoading" >
                      <div v-if="imagePreview['imageGallery2Preview']" class="image-preview-container position-relative">
                        <img :src="imagePreview['imageGallery2Preview']" class="img-thumbnail" alt="Image preview" />
                      </div>
                      
                      <!-- Upload Box as Input -->
                      <div v-else class="image-upload-box">
                        <label for="imgGallery2" class="upload-placeholder">
                          <span class="placeholder-plus">+</span>
                        </label>
                        <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
                      </div>
                    </div>
                    <div  v-if="!galleryEdit">
                      <div v-if="imagePreview['imageGallery2Preview']" class="image-preview-container position-relative">
                        <img :src="imagePreview['imageGallery2Preview']" class="img-thumbnail" alt="Image preview" />
                        <button class="btn-close position-absolute top-0 end-0" @click="removeImage('imageGallery2Preview')"></button>
                      </div>
                      
                      <!-- Upload Box as Input -->
                      <div v-else class="image-upload-box" style="cursor:pointer">
                        <input 
                          type="file" 
                          id="imgGallery2" 
                          class="form-control-file"
                          accept="image/png, image/gif, image/jpeg"
                          @change= "event => handleFileChange2(event, 'imageGallery2Preview')"
                          hidden>
                        <label for="imgGallery2" class="upload-placeholder">
                          <span class="placeholder-plus">+</span>
                        </label>
                        <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
        
                      </div>


                    </div>

                </div>
                <div class="info-column">
                  <label for="imgGallery3" class="form-label">Highlight 3</label>
                   <div v-if="galleryEdit  && !galleryLoading" >
                      <div v-if="imagePreview['imageGallery3Preview']" class="image-preview-container position-relative">
                        <img :src="imagePreview['imageGallery3Preview']" class="img-thumbnail" alt="Image preview" />
                      </div>
                      <div v-else class="image-upload-box">
                      <label for="imgGallery3" class="upload-placeholder">
                        <span class="placeholder-plus">+</span>
                      </label>
                      <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
      
                      </div>
                   </div>
                  <div v-if="!galleryEdit">
                    <div v-if="imagePreview['imageGallery3Preview']" class="image-preview-container position-relative">
                      <img :src="imagePreview['imageGallery3Preview']" class="img-thumbnail" alt="Image preview" />
                      <button class="btn-close position-absolute top-0 end-0" @click="removeImage('imageGallery3Preview')"></button>
                    </div>
                    
                    <!-- Upload Box as Input -->
                    <div v-else class="image-upload-box"  style="cursor:pointer">
                      <input 
                        type="file" 
                        id="imgGallery3" 
                        class="form-control-file"
                        accept="image/png, image/gif, image/jpeg"
                        @change = "event => handleFileChange2(event, 'imageGallery3Preview')"
                        hidden>
                      <label for="imgGallery3" class="upload-placeholder">
                        <span class="placeholder-plus">+</span>
                      </label>
                      <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
      
                    </div>
                  </div>
                </div>
                
                <div class="info-column">
                    <label for="imgGallery4" class="form-label">Highlight 4</label>
                    <div v-if="galleryEdit  && !galleryLoading" >

                      <div v-if="imagePreview['imageGallery4Preview']" class="image-preview-container position-relative">
                        <img :src="imagePreview['imageGallery4Preview']" class="img-thumbnail" alt="Image preview" />
                      </div>
                      
                      <!-- Upload Box as Input -->
                      <div v-else class="image-upload-box">
                        <label for="imgGallery4" class="upload-placeholder">
                          <span class="placeholder-plus">+</span>
                        </label>
                        <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
        
                      </div>

                    </div>
                    <div v-if="!galleryEdit">
                      <div v-if="imagePreview['imageGallery4Preview']" class="image-preview-container position-relative">
                        <img :src="imagePreview['imageGallery4Preview']" class="img-thumbnail" alt="Image preview" />
                        <button class="btn-close position-absolute top-0 end-0" @click="removeImage('imageGallery4Preview')"></button>
                      </div>
                      
                      <!-- Upload Box as Input -->
                      <div v-else class="image-upload-box"  style="cursor:pointer">
                        <input 
                          type="file" 
                          id="imgGallery4" 
                          class="form-control-file"
                          accept="image/png, image/gif, image/jpeg"
                          @change= "event => handleFileChange2(event, 'imageGallery4Preview')"
                          hidden>
                        <label for="imgGallery4" class="upload-placeholder">
                          <span class="placeholder-plus">+</span>
                        </label>
                        <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
        
                      </div>
                    </div>
                </div>
              </div>


              <div class="info-row">
                <div class="info-column">
                     <label for="imgGallery5" class="form-label">Highlight 5</label>
                    <!-- Image Preview Section with Cross Button -->
                    <div v-if="galleryEdit  && !galleryLoading" >
                      <!-- Image Preview Section with Cross Button -->
                      <div v-if="imagePreview['imageGallery5Preview']" class="image-preview-container position-relative">
                        <img :src="imagePreview['imageGallery5Preview']" class="img-thumbnail" alt="Image preview" />
                        </div>
                      
                      <!-- Upload Box as Input -->
                      <div v-else class="image-upload-box"  style="cursor:pointer">
                        <label for="imgGallery5" class="upload-placeholder">
                          <span class="placeholder-plus">+</span>
                        </label>
                        <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
                      </div>
                    </div>
                    <div v-if="!galleryEdit">
                      <!-- Image Preview Section with Cross Button -->
                      <div v-if="imagePreview['imageGallery5Preview']" class="image-preview-container position-relative">
                        <img :src="imagePreview['imageGallery5Preview']" class="img-thumbnail" alt="Image preview" />
                        <button class="btn-close position-absolute top-0 end-0" @click="removeImage('imageGallery5Preview')"></button>
                      </div>
                      
                      <!-- Upload Box as Input -->
                      <div v-else class="image-upload-box"  style="cursor:pointer">
                        <input 
                          type="file" 
                          id="imgGallery5" 
                          class="form-control-file"
                          accept="image/png, image/gif, image/jpeg"
                          @change= "event => handleFileChange2(event, 'imageGallery5Preview')"
                          hidden>
                        <label for="imgGallery5" class="upload-placeholder">
                          <span class="placeholder-plus">+</span>
                        </label>
                        <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
        
                      </div>
                    </div>
                </div>
                <div class="info-column">
                  <label for="imgGallery6" class="form-label">Highlight 6</label>

                    <div v-if="galleryEdit  && !galleryLoading" >
                       <!-- Image Preview Section with Cross Button -->
                      <div v-if="imagePreview['imageGallery6Preview']" class="image-preview-container position-relative">
                        <img :src="imagePreview['imageGallery6Preview']" class="img-thumbnail" alt="Image preview" />
                         </div>
                      
                      <!-- Upload Box as Input -->
                      <div v-else class="image-upload-box" >
                        <label for="imgGallery6" class="upload-placeholder">
                          <span class="placeholder-plus">+</span>
                        </label>
                        <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
        
                      </div>
                    </div>
                  <div v-if="!galleryEdit">
                     <!-- Image Preview Section with Cross Button -->
                    <div v-if="imagePreview['imageGallery6Preview']" class="image-preview-container position-relative">
                      <img :src="imagePreview['imageGallery6Preview']" class="img-thumbnail" alt="Image preview" />
                      <button class="btn-close position-absolute top-0 end-0" @click="removeImage('imageGallery6Preview')"></button>
                    </div>
                    
                    <!-- Upload Box as Input -->
                    <div v-else class="image-upload-box"  style="cursor:pointer">
                      <input 
                        type="file" 
                        id="imgGallery6" 
                        class="form-control-file"
                        accept="image/png, image/gif, image/jpeg"
                        @change= "event => handleFileChange2(event, 'imageGallery6Preview')"
                        hidden>
                      <label for="imgGallery6" class="upload-placeholder">
                        <span class="placeholder-plus">+</span>
                      </label>
                      <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
      
                    </div>
                  </div>

                </div>
                <div class="info-column">
                  <label for="imgGallery7" class="form-label">Highlight 7</label>

                   <div v-if="galleryEdit  && !galleryLoading" >
                      <!-- Image Preview Section with Cross Button -->
                      <div v-if="imagePreview['imageGallery7Preview']" class="image-preview-container position-relative">
                        <img :src="imagePreview['imageGallery7Preview']" class="img-thumbnail" alt="Image preview" />
                          </div>
                      
                      <!-- Upload Box as Input -->
                      <div v-else class="image-upload-box">
                      
                        <label for="imgGallery7" class="upload-placeholder">
                          <span class="placeholder-plus">+</span>
                        </label>
                        <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
        
                      </div>
                      
                   </div>
                  <div v-if="!galleryEdit">
                      <!-- Image Preview Section with Cross Button -->
                      <div v-if="imagePreview['imageGallery7Preview']" class="image-preview-container position-relative">
                        <img :src="imagePreview['imageGallery7Preview']" class="img-thumbnail" alt="Image preview" />
                        <button class="btn-close position-absolute top-0 end-0" @click="removeImage('imageGallery7Preview')"></button>
                      </div>
                      
                      <!-- Upload Box as Input -->
                      <div v-else class="image-upload-box"  style="cursor:pointer">
                        <input 
                          type="file" 
                          id="imgGallery7" 
                          class="form-control-file"
                          accept="image/png, image/gif, image/jpeg"
                          @change= "event => handleFileChange2(event, 'imageGallery7Preview')"
                          hidden>
                        <label for="imgGallery7" class="upload-placeholder">
                          <span class="placeholder-plus">+</span>
                        </label>
                        <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
        
                      </div>
                  </div>
                </div>
                
                <div class="info-column">
                    <label for="imgGallery8" class="form-label">Highlight 8</label>
                    <div v-if="galleryEdit  && !galleryLoading" >
                        <!-- Image Preview Section with Cross Button -->
                        <div v-if="imagePreview['imageGallery8Preview']" class="image-preview-container position-relative">
                          <img :src="imagePreview['imageGallery8Preview']" class="img-thumbnail" alt="Image preview" />
                          </div>
                        
                        <!-- Upload Box as Input -->
                        <div v-else class="image-upload-box">
                          <label for="imgGallery8" class="upload-placeholder">
                            <span class="placeholder-plus">+</span>
                          </label>
                        </div>
                        <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>

                    </div>
                    <div v-if="!galleryEdit">
                       <!-- Image Preview Section with Cross Button -->
                        <div v-if="imagePreview['imageGallery8Preview']" class="image-preview-container position-relative">
                          <img :src="imagePreview['imageGallery8Preview']" class="img-thumbnail" alt="Image preview" />
                          <button class="btn-close position-absolute top-0 end-0" @click="removeImage('imageGallery8Preview')"></button>
                        </div>
                        
                        <!-- Upload Box as Input -->
                        <div v-else class="image-upload-box"  style="cursor:pointer">
                          <input 
                            type="file" 
                            id="imgGallery8" 
                            class="form-control-file"
                            accept="image/png, image/gif, image/jpeg"
                            @change="event => handleFileChange2(event, 'imageGallery8Preview')"
                            hidden>
                          <label for="imgGallery8" class="upload-placeholder">
                            <span class="placeholder-plus">+</span>
                          </label>
                        </div>
                        <p v-if="fileSizeError" class="text-danger">{{ fileSizeError }}</p>
                    </div>
                </div>



              </div>


            </div>
          </div>

    </div>

    <!-- Right Preview -->
    <div class="preview-section" v-if="!isMobile" >
      <div class="preview-card">
        <h3 class="preview-title">Preview</h3>
        <div class="preview-content">
          <Preview
          :imageId ="this.designOption"
          ></Preview>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Navbar from "../components/navbar.vue";
import Preview from "../components/preview.vue"
// import PhonePreview from "./PhonePreview.vue";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { doc, onSnapshot,collection, query, orderBy, getDocs,getDoc,where  } from "firebase/firestore";
import { db } from "../firebaseDB";
import router from "@/router";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {
  getAuth,
  onAuthStateChanged
} from "firebase/auth";

export default {
  components: {
    Navbar,
    Preview,
  },
  data() {
    return {
      isNavbarVisible :true,
      cropping: false, // Toggles cropping state
      cropper: null, // Cropper.js instance
      imageSrc: null, // Source of the uploaded image
      defaultPlaceholder: "https://via.placeholder.com/150", // Placeholder image
      
      name: "",
      email: "",
      companyName: "",
      contactNumber: "",
      role: "",
      fb: "",
      twitter: "",
      insta: "",
      linkedin: "",
      website: "",
      profileImg: "",
      address: "",
      companyID: "",
      accID: "",
      response: "",
      id_token: "",
      apiURL: "https://backend-updateuser-project-553185792079.us-central1.run.app",
      officeNumber:"",
      faxNumber:"",
      description:"",
      youtube:"",
      tiktok:"",
      whatsapp:"",
      tele:"",
      snapchat:"",
      docData: "",
      currentPage: "EditInfo",
      updateStatus: "none",
      designOption:"",
      designTemplateDic:{}, 
      designExclusiveDic:{},
      fileSizeLimitInMB:1,
      fileSizeError:"",
      selectedFile:null,
      swiper: null,
      currUserId: "",
      imagePreview:{
          imageGallery1Preview: null,
          imageGallery2Preview: null,
          imageGallery3Preview: null,
          imageGallery4Preview: null,
          imageGallery5Preview: null,
          imageGallery6Preview: null,
          imageGallery7Preview: null,
          imageGallery8Preview: null,
      },
      staticImagePreview:{
          imageGallery1Preview: null,
          imageGallery2Preview: null,
          imageGallery3Preview: null,
          imageGallery4Preview: null,
          imageGallery5Preview: null,
          imageGallery6Preview: null,
          imageGallery7Preview: null,
          imageGallery8Preview: null,
      },
      pdfFile:"",
      pdfName:"",
      pdfChecker: false,
      clonedObject:{}, 
      currPDFFile:"",
      fbReview:"",
      googleReview:"",
      additionalInfo:"",
      profileEdit:true,
      profileLoading:false,
      contactEdit:true,
      contactLoading:false,
      socialEdit:true,
      socialLoading:false,
      addresseEdit:true,
      addressLoading:false,
      descriptionEdit:true,
      descriptionLoading:false,
      accreditationEdit:true,
      accreditationLoading:false,
      resumeEdit:true,
      resumeLoading:false,
      galleryEdit:true,
      galleryLoading:false,
      isMobile: false, // Detect mobile view
      projectStatus:false,
      projectStatusEdit:true,
      projectStatusLoading:false,
    };
  },
  methods: {
    checkScreenSize() {
      const wasMobile = this.isMobile;
      this.isMobile = window.innerWidth <= 768; // Set breakpoint for mobile
      if (this.isMobile && !wasMobile) {
        this.isPreviewVisible = false; // Hide preview on mobile
        this.isNavbarVisible = false;
        this.$refs.navbar.toggleNavbar(false);
      }
    },
    adjustMainContent(isVisible) {
      this.isNavbarVisible = isVisible; 
    },
    triggerUpload() {
      this.$refs.fileInput.value = "";
      this.$refs.fileInput.click(); // Trigger the file input click
    },
    onImageChange(event) {
      const file = event.target.files[0];
      // Validate file type
      if (!file || !["image/jpeg", "image/png"].includes(file.type)) {
        alert("Please upload a valid JPG or PNG image.");
        return;
      }

      // // Validate file size
      // const fileSizeLimit = this.fileSizeLimitInMB * 1024 * 1024; // Convert MB to bytes
      // if (file.size > fileSizeLimit) {
      //   alert(`File size exceeds the limit of ${this.fileSizeLimitInMB} MB.`);
      //   return;
      // }

      // Read the uploaded file and initialize Cropper
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageSrc = e.target.result; // Set the uploaded image source
        this.cropping = true; // Show cropping custom-custom-modal
        this.$nextTick(() => {
          const imageElement = this.$refs.cropImage;
          if (imageElement) {
            
            this.cropper = new Cropper(imageElement, {
              aspectRatio: 1, // Square crop
              viewMode: 2,autoCropArea: 1, // Full image in view
              dragMode: "move",
              cropBoxResizable: false, // Disable resizing of crop box
              cropBoxMovable: true, // Allow moving the crop box
              ready: () => {
                // Apply a circular mask using CSS
                const cropBox = this.cropper.cropper.querySelector(".cropper-face");
                cropBox.style.borderRadius = "50%";
              },

            });
          }
        });
      };
      reader.readAsDataURL(file);
    },
    cropImage() {
      if (!this.cropper) {
        alert("Cropper is not initialized.");
        return;
      }

      const canvas = this.cropper.getCroppedCanvas({ width: 800, height: 800 });
      if (canvas) {
        this.profileImg = canvas.toBlob(
              (blob) => {
                // Blob contains the JPEG file

                // If needed, create a File object
                const jpegFile = new File([blob], "cropped-image.png", { type: "image/png" });

              this.updateImg(jpegFile);
                
              },
              "image/png",
              1.0
            );
        this.cropper.destroy(); // Destroy cropper instance
        this.cropper = null;
        this.cropping = false; // Close custom-custom-modal
      } else {
        alert("Failed to crop the image. Please try again.");
      }
    },
    cancelCrop() {
      if (this.cropper) {
        this.cropper.destroy(); // Destroy the cropper instance
        this.cropper = null;
      }
      this.cropping = false; // Close cropping custom-custom-modal
    },
    async updateImg(currImg) {
      // this.response = "";
      var profileImg = this.accID + "/profile/profileImg";
      await this.save_image(profileImg, currImg);
      await axios
        .post(this.apiURL + "/updateImage/", {
          accID: this.currUserId,
          profileImg: this.profileImg,
          id_token: this.id_token,
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return;
          }
          // this.response = "success";
          document.getElementById('profile').value = ""
        })
        .catch(() => {
            return "error";
        });
      // setTimeout(() => {
      //   this.response = "";
      // }, 3000);
      return "OK"
    },
    async save_image(filename, image) {
      const storage = getStorage();
      const storageRef = ref(storage, filename);
      await uploadBytes(storageRef, image).then((snapshot) => {
        return getDownloadURL(snapshot.ref).then((downloadURL) => {
          this.profileImg = downloadURL;
        });
      });
    },
    updateDesignTemplate(){
        //basic template
        var updateType = "designOption"
        var changeData ="iLjAw41CX5E9YjPgA0KA"
        axios
          .put(this.apiURL + "/updateInfo/", {
            accID: this.currUserId,
            change_type: updateType,
            change_data: changeData,
            id_token: this.id_token,
          })
          .then((response) => {
            if (response.status == 401) {
              alert("Login Expire! Please Login Again");
              router.push("/");
              return ;
            }
            
            // return "OK"
          })
          .catch(() => {
            // Handle error
            // return "error"
          });
            // return "OK"
            

      },
      updateDesignTemplate2(designID){
        var updateType = "designOption"
        var changeData =designID
        axios
          .put(this.apiURL + "/updateInfo/", {
            accID: this.currUserId,
            change_type: updateType,
            change_data: changeData,
            id_token: this.id_token,
          })
          .then((response) => {
            if (response.status == 401) {
              alert("Login Expire! Please Login Again");
              router.push("/");
              return ;
            }
            this.response = "success";
            setTimeout(() => {
              this.response = "";
            }, 3000);
          })
          .catch(() => {
            // Handle error
            // return "error"
          });
            // return "OK"
      },
      async checkAuthState() {
        const auth = getAuth();
        await onAuthStateChanged(auth, (user) => {
          if (user) {
            user.getIdToken().then((idToken) => {
            
              this.id_token = idToken;
            });
          } else {
            router.push("/");
          }
        });
      },
      async updattePDFFile(){
        if (this.pdfChecker) {
            this.pdfChecker = !this.pdfChecker
            return await this.removePDF();
            
        } else {
          const fileInput = document.getElementById("pdfFile");
          if (fileInput.files[0] != undefined) {
            const fileName = fileInput.files[0].name;
            this.pdfName = fileName
            const result = await this.updatePDF();
            return result;
          }
        }
        
        return "OK"
      },
      async updatePDF() {
      // this.response = "";
      const pdfFile = document.getElementById("pdfFile").files[0];
      
      var pdfFilePath = this.accID + "/PDFFiles/pdfFile";
      await this.save_pdf(pdfFilePath, pdfFile);
      await axios
        .post(this.apiURL + "/updatePDFFile/", {
          userid: this.accID,
          accID: this.currUserId,
          pdfFile: this.pdfFile,
          pdfName: this.pdfName, 
          id_token: this.id_token,
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return;
          }
          // this.response = "success";
          document.getElementById('pdfFile').value = ""
        })
        .catch(() => {
            return "error";
        });
      
          return "OK"
      
    },
    async removePDF() {
      var pdfFilePath = this.accID + "/PDFFiles/pdfFile";
      await axios
        .post(this.apiURL + "/removePDFFile/", {
          accID: this.accID,
          userID: this.currUserId,
          pdfFile: pdfFilePath,
          id_token: this.id_token,
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return;
          }
          // this.response = "success";
          document.getElementById('profile').value = ""
        })
        .catch(() => {
            return "error";
        });
          return "OK"
      // setTimeout(() => {
      //   this.response = "";
      // }, 3000);
    },
    async save_pdf(filename, image) {
      const storage = getStorage();
      const storageRef = ref(storage, filename);
      await uploadBytes(storageRef, image).then((snapshot) => {
        return getDownloadURL(snapshot.ref).then((downloadURL) => {
          this.pdfFile = downloadURL + '&alt=media';
        });
      });
    },
    handleFileChange2(event, id) {
      const file = event.target.files[0];
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 20) {
        this.$refs.fileInput.value = '';
        this.selectedFile = null;
        this.fileSizeError="File Size Exceed Limit of 20MB"
      } else {
        this.selectedFile = file;
        this.fileSizeError=""
        const reader = new FileReader();
        reader.onload = e => {
          this.imagePreview[id] = e.target.result; // Set the image preview
        };
        reader.readAsDataURL(file);
        event.target.value = '';
      }
    },
    
    removeImage(id) {
      this.imagePreview[id] = null; 
      this.$refs.fileInput.value = '';
    },
      toggleProfile(savingStatus) {
        this.profileLoading = true;

        if (savingStatus) {

          axios
            .put(this.apiURL + "/updateInfo/", {
              accID: this.currUserId,
              change_type: "personalInfo",
              name: this.name,
              email: this.email,
              companyName: this.companyName,
              role: this.role,
              id_token: this.id_token,
            })
            .then((response) => {
              if (response.status === 401) {
                alert("Session expired! Please log in again.");
                router.push("/");
                return;
              }
            })
            .catch((error) => {
              console.error("Error updating profile:", error);
              })
            .finally(() => {
              this.profileEdit = !this.profileEdit; // Toggle edit mode
              this.profileLoading = false; // Stop spinner
            });
        } else {
          // If not saving, just toggle edit mode
          this.profileEdit = !this.profileEdit;
          this.profileLoading = false;
        }
      },
      toggleSocial(savingStatus) {
        this.socialLoading = true;

        if (savingStatus) {

          axios
            .put(this.apiURL + "/updateInfo/", {
              accID: this.currUserId,
              change_type: "socialInfo",
              fb: this.fb,
              insta: this.insta,
              twitter: this.twitter,
              linkedin: this.linkedin,
              tiktok:this.tiktok,
              snapchat: this.snapchat,
              youtube: this.youtube,
              website: this.website,
              fbReview: this.fbReview,
              googleReview:this.googleReview,
              id_token: this.id_token,
            })
            .then((response) => {
              if (response.status === 401) {
                alert("Session expired! Please log in again.");
                router.push("/");
                return;
              }
            })
            .catch((error) => {
              console.error("Error updating profile:", error);
              })
            .finally(() => {
              this.socialEdit = !this.socialEdit; // Toggle edit mode
              this.socialLoading = false; // Stop spinner
            });
        } else {
          // If not saving, just toggle edit mode
          this.socialEdit = !this.socialEdit;
          this.socialLoading = false;
        }
      },
      toggleContact(savingStatus) {
        this.contactLoading = true;

        if (savingStatus) {

          axios
            .put(this.apiURL + "/updateInfo/", {
              accID: this.currUserId,
              change_type: "contactInfo",
              contactNumber: this.contactNumber,
              officeNumber: this.officeNumber,
              faxNumber: this.faxNumber,
              tele: this.tele,
              whatsapp:this.whatsapp,
              id_token: this.id_token,
            })
            .then((response) => {
              if (response.status === 401) {
                alert("Session expired! Please log in again.");
                router.push("/");
                return;
              }
            })
            .catch((error) => {
              console.error("Error updating profile:", error);
              })
            .finally(() => {
              this.contactEdit = !this.contactEdit; // Toggle edit mode
              this.contactLoading = false; // Stop spinner
            });
        } else {
          // If not saving, just toggle edit mode
          this.contactEdit = !this.contactEdit;
          this.contactLoading = false;
        }
      },
      toggleAddress(savingStatus) {
        this.addressLoading = true;

        if (savingStatus) {

          axios
            .put(this.apiURL + "/updateInfo/", {
              accID: this.currUserId,
              change_type: "location",
              address: this.address,
              id_token: this.id_token,
            })
            .then((response) => {
              if (response.status === 401) {
                alert("Session expired! Please log in again.");
                router.push("/");
                return;
              }
            })
            .catch((error) => {
              console.error("Error updating address:", error);
              })
            .finally(() => {
              this.addresseEdit = !this.addresseEdit; // Toggle edit mode
              this.addressLoading = false; // Stop spinner
            });
        } else {
          // If not saving, just toggle edit mode
          this.addresseEdit = !this.addresseEdit;
          this.addressLoading = false;
        }
      },

      toggleDescription(savingStatus) {
        this.descriptionLoading = true;

        if (savingStatus) {
         
          axios
            .put(this.apiURL + "/updateInfo/", {
              accID: this.currUserId,
              change_type: "description",
              description: this.description,
              id_token: this.id_token,
            })
            .then((response) => {
              if (response.status === 401) {
                alert("Session expired! Please log in again.");
                router.push("/");
                return;
              }
            })
            .catch((error) => {
              console.error("Error updating description:", error);
              })
            .finally(() => {
              this.descriptionEdit = !this.descriptionEdit; // Toggle edit mode
              this.descriptionLoading = false; // Stop spinner
            });
        } else {
          // If not saving, just toggle edit mode
          this.descriptionEdit = !this.descriptionEdit;
          this.descriptionLoading = false;
        }
      },

      toggleAccreditation(savingStatus) {
        this.accreditationLoading = true;

        if (savingStatus) {
          
          axios
            .put(this.apiURL + "/updateInfo/", {
              accID: this.currUserId,
              change_type: "addInfo",
              additionalInfo: this.additionalInfo,
              id_token: this.id_token,
            })
            .then((response) => {
              if (response.status === 401) {
                alert("Session expired! Please log in again.");
                router.push("/");
                return;
              }
            })
            .catch((error) => {
              console.error("Error updating description:", error);
              })
            .finally(() => {
              this.accreditationEdit = !this.accreditationEdit; // Toggle edit mode
              this.accreditationLoading = false; // Stop spinner
            });
        } else {
          // If not saving, just toggle edit mode
          this.accreditationEdit = !this.accreditationEdit;
          this.accreditationLoading = false;
        }
      },

      async toggleResume(savingStatus) {
        this.resumeLoading = true;

        if (savingStatus) {
          await this.updattePDFFile();
          // If not saving, just toggle edit mode
          this.resumeEdit = !this.resumeEdit;
          this.resumeLoading = false;
        } else {
          // If not saving, just toggle edit mode
          this.resumeEdit = !this.resumeEdit;
          this.resumeLoading = false;
        }
      },

      async toggleGallery(savingStatus) {
        this.galleryLoading = true;
        
        if (savingStatus) {
          await this.updateGalleryImages();
          // If not saving, just toggle edit mode
          this.galleryEdit = !this.galleryEdit;
          this.galleryLoading = false;
        } else {
          // If not saving, just toggle edit mode
          this.galleryEdit = !this.galleryEdit;
          this.galleryLoading = false;
        }
      },

      async updateGalleryImages(){
      this.clonedObject = JSON.parse(JSON.stringify(this.imagePreview));

        for (let key in this.clonedObject) {
          let value = this.clonedObject[key]
            if (value !== this.staticImagePreview[key]){
              var savedGalleryImage = this.accID + "/imageGallery/"+key;
              if (value == "" || value == undefined){
                this.clonedObject[key] = ""
              }else{
                var galleryImage =  value;
                await this.save_galleryImage(savedGalleryImage, galleryImage,key);
              }
              await axios
                .post(this.apiURL + "/updateGalleryImage/", {
                  accID: this.accID,
                  userID: this.currUserId,
                  galleryImage: this.clonedObject[key],
                  galleryKey: key,
                  filePath: savedGalleryImage,
                  id_token: this.id_token,
                })
                .then((response) => {
                  if (response.status == 401) {
                    alert("Login Expire! Please Login Again");
                    router.push("/");
                    return;
                  }
                })
                .catch(() => {
                    return "error";
                    });
                } 
          }
          this.imagePreview = this.clonedObject
    }, 
    async save_galleryImage(filename, image,key){
      const storage = getStorage();
      const storageRef = ref(storage, filename);
      const imageBlob = this.base64ToBlob(image);
      await uploadBytes(storageRef, imageBlob).then((snapshot) => {
          return getDownloadURL(snapshot.ref).then((downloadURL) => {
            this.clonedObject[key] = downloadURL;
          });
      });
    },
    base64ToBlob(dataURL) {
        // Split the data URL at the comma to separate the base64 string
        const parts = dataURL.split(',');
        if (parts.length !== 2) {
            throw new Error('Invalid data URL');
        }

        // Decode the base64 string
        const decodedData = atob(parts[1]);
        const byteArrays = [];

        for (let offset = 0; offset < decodedData.length; offset += 512) {
            const slice = decodedData.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        // Extract the MIME type from the data URL
        const mimeType = parts[0].split(':')[1].split(';')[0];

        return new Blob(byteArrays, { type: mimeType });
    },
    toggleProjectStatus(savingStatus) {
        this.projectStatusLoading = true;

        if (savingStatus) {
          axios
            .put(this.apiURL + "/updateInfo/", {
              accID: this.currUserId,
              change_type: "projectStatus",
              projectStatus: this.projectStatus,
              id_token: this.id_token,
            })
            .then((response) => {
              if (response.status === 401) {
                alert("Session expired! Please log in again.");
                router.push("/");
                return;
              }
            })
            .catch((error) => {
              console.error("Error updating profile:", error);
              })
            .finally(() => {
              this.projectStatusEdit = !this.projectStatusEdit; // Toggle edit mode
              this.projectStatusLoading = false; // Stop spinner
            });
        } else {
          // If not saving, just toggle edit mode
          this.projectStatusEdit = !this.projectStatusEdit;
          this.projectStatusLoading = false;
        }
      },

  },
  async mounted() {

    this.accID = this.$store.getters.getUserId;
    await this.checkAuthState();
    this.checkScreenSize(); // Check on page load
    window.addEventListener("resize", this.checkScreenSize);
    window.addEventListener("touchmove", (event) => {
      if (this.isMobile) {
        event.stopPropagation();
      }
    });
    const docRef = await doc(db, "userProfile", this.accID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      this.currUserId = this.accID
      await onSnapshot(docRef, (doc) => {
          var userData = doc.data();
          this.docData = userData;
          this.address = userData.address;
          this.contactNumber = userData.contactNumber;
          this.email = userData.email;
          this.fb = userData.fb;
          this.insta = userData.insta;
          this.linkedin = userData.linkedin;
          this.name = userData.name;
          this.profileImg = userData.profileImg;
          this.role = userData.role;
          this.twitter = userData.twitter;
          this.website = userData.website;
          this.companyName = userData.companyName;
          this.officeNumber = userData.officeNumber;
          this.faxNumber = userData.faxNumber;
          this.description = userData.description;
          this.youtube = userData.youtube;
          this.tiktok = userData.tiktok;
          this.whatsapp = userData.whatsapp;
          this.tele = userData.tele;
          this.snapchat = userData.snapchat;
          this.currPDFFile = userData.PdfFile;
          this.pdfName = userData.PdfName;
          if (userData.fbReview!=undefined){
              this.fbReview= userData.fbReview;
          }else{
              this.fbReview= "";
          }
          if (userData.googleReview!=undefined){
              this.googleReview= userData.googleReview;
          }else{
              this.googleReview= "";
          }
          if (userData.additionalInfo!=undefined){
              this.additionalInfo= userData.additionalInfo;
          }else{
              this.additionalInfo= "";
          }
          if (userData.imagePreview!=undefined){
              this.imagePreview = userData.imagePreview
              this.staticImagePreview =ref(JSON.parse(JSON.stringify(userData.imagePreview)));
          }else{
              this.docData.imagePreview = this.staticImagePreview
          }
          if(userData.projectStatus!= undefined){
            this.projectStatus = userData.projectStatus
          }
          this.designOption= userData.designOption
          
          if (userData.designOption==undefined){
              this.updateDesignTemplate()
          } 
        });
    }else{
      const q = query(collection(db, "userProfile"), where("userid", "==", this.accID ));
      await onSnapshot(q, async(doc1) => {
        if (!doc1.empty) {
          this.currUserId = doc1.docs[0].id
          const userData = doc1.docs[0].data();
          this.docData = userData;
          this.address = userData.address;
          this.contactNumber = userData.contactNumber;
          this.email = userData.email;
          this.fb = userData.fb;
          this.insta = userData.insta;
          this.linkedin = userData.linkedin;
          this.name = userData.name;
          this.profileImg = userData.profileImg;
          this.role = userData.role;
          this.twitter = userData.twitter;
          this.website = userData.website;
          this.companyName = userData.companyName;
          this.officeNumber = userData.officeNumber;
          this.faxNumber = userData.faxNumber;
          this.description = userData.description;
          this.youtube = userData.youtube;
          this.tiktok = userData.tiktok;
          this.whatsapp = userData.whatsapp;
          this.tele = userData.tele;
          this.snapchat = userData.snapchat;
          this.currPDFFile = userData.PdfFile;
          this.pdfName = userData.PdfName;

           if (userData.fbReview!=undefined){
              this.fbReview= userData.fbReview;
          }else{
              this.fbReview= "";
          }
          if (userData.googleReview!=undefined){
              this.googleReview= userData.googleReview;
          }else{
              this.googleReview= "";
          }
          if (userData.additionalInfo!=undefined){
              this.additionalInfo= userData.additionalInfo;
          }else{
              this.additionalInfo= "";
          }
          if (userData.imagePreview!=undefined){
            this.imagePreview = userData.imagePreview
            this.staticImagePreview =ref(JSON.parse(JSON.stringify(userData.imagePreview)));
          }else{
              this.docData.imagePreview = this.staticImagePreview
          }
          if(userData.projectStatus!= undefined){
            this.projectStatus = userData.projectStatus
          }
          this.designOption= userData.designOption
          
          if (userData.designOption==undefined){
              this.updateDesignTemplate()
          } 
          }else{
            router.push("/NotFound");
          }
        })
    }
    const colRef  = collection(db, "designTemplates");
  
    const deisgnQuery = query(colRef, orderBy("createdTime"));
    const designSnap = await getDocs(deisgnQuery)
    const designTemplateDic = {};
    const designExclusiveDic = {};
    designSnap.forEach(doc => { 
       if (doc.data().exclusive && doc.data().exclusiveList.includes(this.$store.getters.getUserId)) {
          designExclusiveDic[doc.id] = doc.data();
        } else if (!doc.data().exclusive) {
          designTemplateDic[doc.id] = doc.data();
        }
    });
    this.designTemplateDic = designTemplateDic;
    this.designExclusiveDic = designExclusiveDic;
  },
};
</script>
<style scoped>
.main-screen {
  display: flex;
  /* height: 100vh; */
  gap: 20px;
}

.navbar-section {
  width: 250px;
  text-align: left;
}

.main-content {
  flex: 2;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow-y: auto;
  text-align: left !important; /* Override Bootstrap */
  justify-content: flex-start !important; /* Ensure left alignment */
  align-items: flex-start !important; /* For flex containers */
}

.profile-picture img {
  max-width: 100px;
  border-radius: 50%;
  /* display: block; */
  /* margin: 0 auto 10px; */
}

/* .profile-picture input {
  display: block;
  margin: 10px auto;
} */

.sections .section {
  margin-bottom: 20px;
}

.preview-section {
  width: 350px; /* Matches the card width */
  height: auto; /* Adjusts to the content height */
  z-index: 1000; /* Ensures it stays above other elements */
  background-color: transparent; /* Transparent background to blend with the page */
}
.navbar-section {
  transition: width 0.3s ease;
  overflow: hidden; /* Prevent content overflow when hidden */
}

.navbar-section.hidden {
  width: 0; /* Collapse navbar */
}
.main-content {
  flex: 2;
  transition: flex 0.3s ease; /* Smooth adjustment */
}

.main-content.full-width {
  flex: 1; /* Take over the space */
}
.main-screen{
  background-color: #f9f9f9;
  text-align: left !important; /* Override Bootstrap */
  justify-content: flex-start !important; /* Ensure left alignment */
  align-items: flex-start !important; /* For flex containers */
}

.profile-picture {
  text-align: center;
}

.image-container {
  display: flex;
  align-items: left;
  gap: 20px; /* Space between image and the upload actions */
  margin-bottom: 10px;
}

.image-container img {
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Circular image */
  object-fit: cover;
  background-color: #e0e0e0; /* Fallback background */
}

.upload-actions {
  display: flex;
  flex-direction: column; /* Stack button and text vertically */
  align-items: flex-start; /* Align text and button to the left */
  padding-top:20px;
}

.upload-button {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 10px; /* Space between button and text */
}

.upload-button:hover {
  background-color: #f7f7f7;
  border-color: #aaa;
}

.file-input {
  display: none; /* Hidden input */
}

.upload-info {
  font-size: 12px;
  color: #666;
  margin-left: -12px;  
}

/* custom-custom-modal Styles */
.custom-custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.custom-custom-modal img.crop-img {
  max-width: 100%;
  max-height: 300px;
}

.custom-custom-modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.cropper-container .cropper-face {
  border-radius: 50% !important; /* Makes the crop box circular */
}

.crop-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.crop-button:hover {
  background-color: #45a049;
}

.cancel-button {
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

.custom-custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
/*info*/
/* Personal Info Card */
.personal-info-card {
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

/* Card Header */
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: transparent !important;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}

.save-button {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-size: 0.9rem;
  border: 1px solid #757575;
  background-color: #fff;
  color: #333;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.save-button:hover {
  background-color: #f5f5f5;
}

.save-button i {
  margin-right: 5px;
}

/* Card Content */
.info-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: .5rem 1rem;
}

.info-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.info-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.info-label {
  font-size: 0.85rem;
  color: #aaa;
  margin-bottom: 5px;
}

.info-value {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.info-input {
  font-size: 1rem;
  color: #333;
  border: none;
  border-bottom: 1px solid #ccc; /* Solid underline */
  outline: none;
  background: transparent;
  width: 100%;
}

.info-input:focus {
  border-bottom: 1px solid #000;  /* Highlight border on focus */
}


.save-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 0.9rem;
  padding: 8px 12px;
  /* border: none; */
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Spinner Style */
.spinner {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  }
.image-preview-container {
  position: relative;
  width: 100px; /* Fixed width, adjust as needed */
  /* height: 100px; Fixed height, adjust as needed */
  padding: 0;
  margin: 0; 
}

.image-upload-box {
  border: 2px dashed #ccc;
  width: 100px; 
  height: 100px; 
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; 
  height: auto; 
  padding: 10px;  */
}
/* Preview Section */
.preview-card {
  position: fixed; /* Keeps the card fixed on the right */
  top: 20px; /* Adds spacing from the top */
  right: 20px; /* Aligns it to the right */
  width: 350px; /* Fixed width for the card */
  background-color: #fff; /* White background for better visibility */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for the card */
  padding: 20px; /* Padding inside the card */
  z-index: 1000; /* Ensures it stays above other elements */
}

.preview-title {
  font-size: 1.2rem; /* Title font size */
  font-weight: bold; /* Bold text */
  margin-bottom: 10px; /* Spacing below the title */
}

.preview-content {
  height: 500px; /* Fixed height for the card */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  overflow: hidden; /* Hides overflow content */
  background-color: #f9f9f9; /* Optional background */
}

.preview-content img {
  max-width: 100%; /* Ensures the image doesn't exceed the container's width */
  max-height: 100%; /* Ensures the image doesn't exceed the container's height */
  object-fit: contain; /* Maintain aspect ratio and fit the container */
}
@media (max-width: 768px) {
  .personal-info-card {
    font-size: 14px; /* Reduce font size for better fit */
  }

  .info-row {
    flex-direction: column; /* Stack items vertically */
  }

  .info-value {
    font-size: 12px; /* Smaller font for better alignment */
    overflow: hidden; /* Prevent overflow */
    text-overflow: ellipsis; /* Show "..." if text is too long */
  }
}

</style>